import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

export default function Cloudsolutions() {

         React.useEffect(() => 
  {
      document.title="Cloud Solutions | AdventBiz Tech"

   
     
  }, [])


  return (

        <>
        <Header/>
  <header className="header-2 ">
        <div className="page-header min-vh-100 relative" 
        style={{backgroundImage: 'url("../assets/img/services/cloud_solutions.jpg")'}}>
          <span className="mask bg-gradient-primary opacity-5" />
          <div className="container">
            <div className="row ">
              <div className="col-lg-10 text-left mt-5" style={{backgroundColor: 'rgb(20 20 20 / 60%)',borderRadius:"10px"}}>
               
                <h1 style={{fontFamily:'revert',
               
                
                }} className="text-white pt-3  display-1">Cloud Solutions</h1>
                <p className="lead text-white mt-3 " style={{fontSize:'1.1em'}}>

                Cloud Solutions and DevOps provides computing services to individuals, businesses, and organizations that need to store, manage and process their data online. These applications are offered through Cloud Solutions platforms on the internet. Cloud Solutions & DevOps is allowing you to keep up-to-date with the latest developments, articles, updates and future innovations happening in the technology world. Companies such as Google and Amazon Web Services are implementing cloud. </p>
              </div>
            </div>
          </div>
        </div>
      </header>


       <div className='container pt-5'>       

        {/* <div class="row mt-5">
      <div class="col-lg-12 mx-auto">
        <div class="p-3 text-center mb-2">
         
          <h2 class="mt-3">Check out what&#39;s in Digital Transformation</h2>
        
        </div>
      </div>
    </div> */}


       <div className="row mt-lg-5 mt-4">
        <div className="col-lg-6 col-md-6 mb-5">
        
          <div className="card">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl">
                {/* <i className="material-icons opacity-10">manage_accounts</i> */}
                <span  className='myicons' >A</span>
            
               
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">AWS, Azure & GCP Cloud Solutions</h5>
              <p className="mb-3">Our mission is to achieve maximum value for our customers by providing cutting edge solutions and efficient, reliable delivery. Our solutions are designed to solve a specific business need through the power of the cloud. Our current offerings cover the gamut from simple database integration to complex analytical services using Amazon Web Services or Microsoft Azure. Our clients range from small businesses to Fortune 10 companies, and from startups to Fortune 500 organizations.</p>
              {/* <a href="javascript:;" className="font-weight-bold text-xs text-uppercase font-weight-bolder text-primary icon-move-right">
                Check more
                <i className="fas fa-arrow-right text-xs ms-1" aria-hidden="true" />
              </a> */}
            </div>
          </div>
        </div>
      



                   <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-danger shadow-danger shadow text-center border-radius-xl">
                 <span  className='myicons' >C</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2"> Cloud Migration Solutions</h5>
              <p className="mb-3">We at Cloud Migration solutions are dedicated to helping businesses migrate from technology to something new and fresh. Our goal is to help you make the transition easier by combining our years of experience in software development with the help of some of the world's most innovative technology companies to create a solution that fits your exact needs. Whether you’re looking for a complete migration or just a piece of the puzzle, our team will work closely with your IT department and provide insight into your business’ current state so that we can craft an action plan that works best for you.
                </p>
            </div>
          </div>
        </div>    

  <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-success shadow-success shadow text-center border-radius-xl">
                 <span  className='myicons' >D</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">DevOps Engagement</h5>
              <p className="mb-3">DevOps Engagement is a focused on helping businesses deliver value to their customers. By leveraging cutting-edge technology and analytics, we help our clients grow their businesses through digital marketing solutions that drive engagement and conversion. We believe that finding smart solutions for your business isn’t hard. It just takes a little time. That's why we have built a team of experienced professionals with a shared passion for learning and teaching others about the latest marketing trends.
                </p>
            </div>
          </div>
        </div>



        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-xl">
                 <span  className='myicons' >I</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Infrastructure Project Management</h5>
              <p className="mb-3">Infrastructure project Management provides experienced service providers, who have the ability to complete complex assignments in a timely manner. We are always looking for new ways to help our customers solve their problems. If you are looking for a company that specializes in infrastructure projects but also offers other services than just that, you’re at the right place.</p>
              
            </div>
          </div>
        </div>



        
        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-info shadow-info shadow text-center border-radius-xl">
               <span  className='myicons' >N</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Network Design, Implementation & Management</h5>
              <p className="mb-3">Network Design, Implementation & Management is a one-stop shop for all your network needs. Whether you are looking for a new place to work, or need some help with your existing infrastructure, our team of seasoned professionals have the experience and expertise to help you get it done right. We offer a wide range of services including: installation, configuration and maintenance of wired and wireless networks; site surveys; LAN design; mobile device management; managed IT services including Wi-Fi deployment and troubleshooting. Our experienced team will take the time to understand your needs and help you design the network that works best for you.
</p>
             
            </div>
          </div>
        </div>


        
         <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-warning shadow-warning shadow text-center border-radius-xl">
                   <span  className='myicons' >D</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4 px-sm-5 ">
              <h5 className="mt-2">Datacenter Design, Implementation & Management</h5>
              <p className="mb-3">Our goal at Advent Biztech Solutions Pvt. Ltd. is to be the partner of choice when it comes to building, managing, and optimizing your datacenter. We work with our clients to build the right architecture and infrastructure for their business. We help you to determine the right approach to decouple application workloads from infrastructure, optimize the performance of your workloads, achieve best-in-class operational results and increase your chances of winning new customers
</p>
             
            </div>
          </div>
        </div>
   



  









      </div>
</div>    

        <Footer/>
        </>
    
  )
}
