import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'



export default function Aboutus() {

      React.useEffect(() => 
  {
      document.title="About Us | AdventBiz Tech"

   
     
  }, [])

  return (

    <>
    <Header/>


  <header className="header-2 ">
        <div className="page-header min-vh-100 relative" style={{backgroundImage: 'url("../assets/img/about_us_back.jpg")'}}>
          <span className="mask bg-gradient-primary opacity-3" />
      
        </div>
      </header>



      {/* <header className="header-2 ">
    
        <div className="page-header min-vh-100 relative" >
          <span className="mask bg-gradient-primary opacity-5" />
  <video width="100%"  autoPlay muted loop  >
                                    <source src={"../assets/video/backvideo.mp4"} type="video/mp4" />

                                 Your browser does not support HTML video.
                                 </video>


        </div>
      </header> */}


         <div className="" style={{marginTop:'-40rem',background:'rgb(0 0 0 / 60%)'  }}>

        

      <section className="py-5">
      
        <div className="container">


  <div class="py-3 align-items-center row">

     <div className="col-lg-7" style={{zIndex:'99',backgroundColor:'rgb(0 0 0 / 60%)'}}>

                 <div class="py-3 align-items-center row">


                   <div class="col-sm-12">
        <h2 class="mb-0 text-white" style={{fontFamily:'revert'}}>Our Company</h2>
          <p class="mb-0 mt-2" >
        <span style={{color:'#fff'}}> Advent Biztech Solutions is a fast growing, innovative and tech driven organization. Our main service provisioning is the IT solutions. We provide integrated consulting services to help businesses improve their internal systems, processes and procedures.
      <br/>
Advent Biztech Solutions Private Limited was established year 9th September 2019.We are a team of dedicated  150+ professionals in all services, with highly experienced professionals, experience and expertise. We are committed towards excellence and value every ounce of our resource.
     <br/><br/> </span>   </p>
      </div>
  
     

      <div class="col-sm-12">
        <h2 class="mb-0 text-white" style={{fontFamily:'revert'}}>Our Approach</h2>
          <p class="mb-0 mt-2" >
        <span style={{color:'#fff'}}>  Our Approach
A lot of factors contribute towards building a company’s brand image in the market and a conducive work culture ranks somewhere near the top of that list. Fair business practices, extensive growth prospects, open and friendly work culture - all such factors help to augment our company brand image and help our company position itself securely in its operating market.
      </span>   </p>
      </div>
    </div>

      <div class="py-3 align-items-center row">
     

      {/* <div class="col-sm-12" style={{zIndex:'99',backgroundColor:'rgb(0 0 0 / 60%)'}}>
        <h2 class="mb-0 text-white" style={{fontFamily:'revert'}}>Work Culture & Core Values</h2>
      
          <p class="mb-0 mt-2">
           <span style={{color:'#fff'}}> Employees are the most important resources that a business can have. We believe A happy employee is a productive employee and a dedicated and productive taskforce can take the company to newer heights.

A happy employee is a productive employee and a dedicated and productive taskforce can take the company to newer heights. Present age employees are clear about their expectations from the job and have a clearly defined career roadmap. Some employees prefer vertical career growth while others may prefer operating in the horizontal growth spectrum.
We feel there are a few key traits, irrespective of the businesses and domains that are still important enough to attract the most dedicated and motivated employees:
   </span>     </p>
      
      </div> */}
    </div>

    </div>

     <div className="col-lg-5" style={{zIndex:'99',backgroundColor:'rgb(0 0 0 / 60%)'}}>
        <img class="w-100 border-radius-md shadow-lg" src="../assets/img/work_culture.jpg" alt="image"></img>
       

</div>

</div>

        

        </div>
      </section>

       



</div>








  <div className="pt-7 px-3 md-4" style={{background: '#015A85',  
background: '-webkit-linear-gradient(bottom left, #015A85, #A30313)',
background: '-moz-linear-gradient(bottom left, #015A85, #A30313)',
background: 'linear-gradient(to top right, #015A85, #A30313)'  }}>

  
 <div className="container">
 
                <div className="row align-items-center ">

                      <div class="col-sm-12 mt-2" >
        <h2 class="mb-0" style={{fontFamily:'revert',color:'#99caec'}}>Work Culture & Core Values</h2>
      
          <p class="mb-0 mt-2">
           <span style={{color:'#fff'}}> Employees are the most important resources that a business can have. We believe A happy employee is a productive employee and a dedicated and productive taskforce can take the company to newer heights.

A happy employee is a productive employee and a dedicated and productive taskforce can take the company to newer heights. Present age employees are clear about their expectations from the job and have a clearly defined career roadmap. Some employees prefer vertical career growth while others may prefer operating in the horizontal growth spectrum.
We feel there are a few key traits, irrespective of the businesses and domains that are still important enough to attract the most dedicated and motivated employees:
   </span>     </p>
      
      </div>

            <div className="col-lg-12 mt-5 mb-5">
              <div className="row justify-content-start">
                <div className="col-md-6">
                  <div className="info">
                    {/* <i className="material-icons text-3xl text-gradient text-info mb-3">public</i> */}
                    <img src='../assets/img/icons/core_values.png' alt="values" style={{width:'50px',height:'50px',marginBottom:'10px'}}/>
                    <h5 style={{color:'#d2515a'}}>Strong core values</h5>
                    <p style={{color:'#fff'}}>Employees prefer working for a company that staunchly follows its core defined values. Strict adherence to core values bespeaks of a company that conducts its businesses responsibly and with integrity.</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info">
                   <img src='../assets/img/icons/target.png' alt="values" style={{width:'50px',height:'50px',marginBottom:'10px'}}/>
                    <h5 style={{color:'#d2515a'}}>Clear future targets</h5>
                    <p style={{color:'#fff'}}>Every individual wants to be a part of something that is destined to make it big in its business sphere. People like to work in a company that has a set of clearly defined goals and is steadily moving towards achieving the set targets with a well-defined business strategy.</p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-start mt-4">
                <div className="col-md-6">
                  <div className="info">
                     <img src='../assets/img/icons/leadership.png' alt="values" style={{width:'50px',height:'50px',marginBottom:'10px'}}/>
                    <h5 style={{color:'#d2515a'}}>Strong leadership</h5>
                    <p style={{color:'#fff'}}>A strong company leadership that has an excellent track record of demonstrating strategic brilliance, integrity, strong entrepreneurial traits acts as a guiding force for individuals and inspires them to excel and work hard towards a central company goal.</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info">
                    <img src='../assets/img/icons/growth.png' alt="values" style={{width:'50px',height:'50px',marginBottom:'10px'}}/>
                    <h5 style={{color:'#d2515a'}}>Tangible growth prospects</h5>
                    <p style={{color:'#fff'}}>While salary compensation is an important determinant for employee hiring and retention, the well-informed and career conscious employees of present times would prefer joining and staying on at a company that can help her/him chart a clearly defined career graph.</p>
                  </div>
                </div>
              </div>


                            <div className="row justify-content-start mt-4">
                <div className="col-md-6">
                  <div className="info">
                      <img src='../assets/img/icons/team.png' alt="values" style={{width:'50px',height:'50px',marginBottom:'10px'}}/>
                    <h5 style={{color:'#d2515a'}}>Passionate about its people</h5>
                    <p style={{color:'#fff'}}>A company that values its employees and acknowledges their personal/work related challenges is likely to earn the respect of its employees. Introducing several employee-centric work initiatives such as flexible timings, corporate health insurance for employees and their families, recreational facilities, employee counselors, etc. go a long way in making a satisfied employee.</p>
                  </div>
                </div>
                <div className="col-md-6">
                  {/* <div className="info">
                    <i className="material-icons text-3xl text-gradient text-info mb-3">3p</i>
                    <h5>Tangible growth prospects</h5>
                    <p>While salary compensation is an important determinant for employee hiring and retention, the well-informed and career conscious employees of present times would prefer joining and staying on at a company that can help her/him chart a clearly defined career graph.</p>
                  </div> */}
                </div>
              </div>
            </div>
        
          </div>


 </div>


         </div>



  <div className="px-3 md-4 " style={{backgroundImage: 'linear-gradient(195deg, #42424a 0%, #191919 100%)'  }}>
 <div className="container">
         
<section className="pb-5 position-relative bg-gradient-dark mx-n3 mb-n3">
        <div className="container">
          <div className="row">
            <div className="col-md-8 text-start  mt-5">
             
              
              {/* <p className="text-white opacity-8 mb-0">There’s nothing I really wanted to do in life that I wasn’t able to get good at. That’s my skill.</p> */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className=" mt-4 ">
                <div className="row">
                  <div className="col-lg-5 col-md-6 col-12 mt-2">
                    <a href="javascript:;">
                      <div className="p-3 pe-md-0">
                        <img className="w-100 border-radius-md shadow-lg" src="../assets/img/chandrakant_sanglikar.jpg" alt="image" />
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-7 col-md-6 col-12 my-auto">
                    <div className="card-body ps-lg-0">
                     <h3 className="text-white z-index-1 position-relative" style={{fontFamily:'revert'}}>Group Chairman</h3>
                      <h5 className="mb-0" style={{color:'#0fa1db'}} >Mr. Chandrakant Ramchandra Sanglikar</h5>
                      {/* <h6 className="text-info">UI Designer</h6> */}
                      <p className='mt-1' style={{lineHeight:'1.5rem',color:'white'}}>
                    A socialist emerged from Sangli District of Maharashtra is a legend with ethics, vision for tomorrow and genuine dream of reshaping reality. He is contributing for better tomorrow via various roadmaps on the vast atlas of property & land acquisition for over a decade. Mr. Sanglikar holds Masters in Business Administration with a feather of Law as an add-on which indeed helped him in accomplishing major business deals and ventures with better transaction and business prospects resulting every acquisition till date as a brick in a pillar of trust he achieved.
                      <br/>  <br/>
                      His ethical values played another vital role in helping him reach this zenith, with a contribution of true people and his practical dreams leaving benchmark for today’s generation. The landmarks of each acquisition had only become memorable with every step in satisfactory venture evaluating a colossal in major tier 2 & 3 cities including metros throughout Maharashtra, Bangalore & Goa and many emerging cities the cross nation, which helped Mr. Sanglikar to stand among highest in land acquisition in the entire competition.
                      <br/>  <br/>
The steps towards charity and social contribution always displays bright shadow of true human nature, Mr. Sanglikar proved the sense of Ancestor blessings via initiating and successfully running a educational wing & shed in-form of hostel for the future generation’s better education needs in Sangli Dristrict , Maharashtra for free of cost with no expectation of any financial returns or personal benefit , but boost of knowledge and education in next generation.
                      
                      
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
        
        </div>
      </section>
</div>
</div>


    <Footer/>

    </>
  )
}
