import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

export default function Sapsolutions() {

         React.useEffect(() => 
  {
      document.title="SAP Solutions & Erp Services | AdventBiz Tech"

   
     
  }, [])


  return (

        <>
        <Header/>
  <header className="header-2 ">
        <div className="page-header min-vh-100 relative" 
        style={{backgroundImage: 'url("../assets/img/services/sap_solutions.jpg")'}}>
          <span className="mask bg-gradient-primary opacity-5" />
          <div className="container">
            <div className="row ">
              <div className="col-lg-10 text-left mt-5" style={{backgroundColor: 'rgb(20 20 20 / 60%)',borderRadius:"10px"}}>
               
                <h1 style={{fontFamily:'revert',
               
                
                }} className="text-white pt-3  display-1">SAP Solutions & Erp Services</h1>
                <p className="lead text-white mt-3 " style={{fontSize:'1.1em'}}>

                SAP  solutions offer you the opportunity to easily and cost effectively streamline your business processes. We provide customized SaaS solutions for small and medium-sized businesses, based on both existing SAP backend systems and new cloud-based technologies. An efficient business process can lead to higher revenue and faster time-to-market of new products, the introduction of new services.
 </p>
              </div>
            </div>
          </div>
        </div>
      </header>


       <div className='container pt-5'>       

        {/* <div class="row mt-5">
      <div class="col-lg-12 mx-auto">
        <div class="p-3 text-center mb-2">
         
          <h2 class="mt-3">Check out what&#39;s in Digital Transformation</h2>
        
        </div>
      </div>
    </div> */}


       <div className="row mt-lg-5 mt-4">
        <div className="col-lg-6 col-md-6 mb-5">
        
          <div className="card">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl">
                {/* <i className="material-icons opacity-10">manage_accounts</i> */}
                <span  className='myicons' >S</span>
            
               
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">SAP Consultation & Implementation</h5>
              <p className="mb-3">If you need an SAP Consultation or Implementation, then SAP Consultant's is the place for you. We are a team of professionals who are able to provide individualized consulting and implementation services from our experience in the field. With that experience we will be able to develop an innovative solution that fits your company's needs. We can help you understand how your company can benefit from integrating SAP with your business processes and operations. We have a team of experts who have provided high quality consulting services over the past 20 years, helping to achieve our clients’ goals and objectives.
</p>
              {/* <a href="javascript:;" className="font-weight-bold text-xs text-uppercase font-weight-bolder text-primary icon-move-right">
                Check more
                <i className="fas fa-arrow-right text-xs ms-1" aria-hidden="true" />
              </a> */}
            </div>
          </div>
        </div>
      



                   <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-danger shadow-danger shadow text-center border-radius-xl">
                 <span  className='myicons' >A</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Application Management Services</h5>
              <p className="mb-3">Our Application Management Services include the following: - Application Development and maintenance, including security updates - Performance monitoring & tuning - Migration from older systems, to new systems. Application Management Services are designed to streamline the implementation and deployment of hundreds of third party applications. It provides you with an optimized, efficient way for managing your business software applications. Our Technology Acquisition & Licensing services enable us to handle the entire lifecycle of new applications from purchase to maintenance.</p>
             
            </div>
          </div>
        </div>    

  <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-success shadow-success shadow text-center border-radius-xl">
                 <span  className='myicons' >A</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Audit Management Services</h5>
              <p className="mb-3">Our Audit Management Services help you deploy your ERP audit work, so that it can help you make informed and more efficient decisions. We’ll customize our audit process for your business processes and workflow so that we can adapt our approach to your organization. Our audit management service team helps companies ensure that their annual and/or quarterly financial statements are accurate and prepared in a timely manner. We provide comprehensive, reliable and cost-effective audit services to our clients' organizations throughout the United States.
</p>
              
            </div>
          </div>
        </div>



        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-info shadow-info shadow text-center border-radius-xl">
                 <span  className='myicons' >T</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Technical Consulting</h5>
              <p className="mb-3">At Technical Consulting, we specialize in helping companies with their technical consulting needs. From strategy and design to implementation and testing, we bring the right solutions to your organization. As a technical consultant, you will design, develop and monitor solutions built on top of a managed service platform like AWS. Working with a team of developers, you will build and deploy applications in AWS and ensure that your customers are informed about their systems at all times. You will also work together with the technical team to diagnose issues and efficiently resolve them ASAP.

</p>
              
            </div>
          </div>
        </div>




  
   
  









      </div>
</div>    

        <Footer/>
        </>
    
  )
}
