import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

export default function Services() {

       React.useEffect(() => 
  {
      document.title="Services | AdventBiz Tech"

      document.body.style.backgroundImage = "url('assets/img/services.jpg')";
   
     
  }, [])


  return (
    
    <>

    <Header/>
    <div className="container">
            <div className="row ">
              <div className="col-lg-12 text-center mt-5" >
               
       <h1 style={{fontFamily:'revert'}} className="text-white pt-3 mt-8  display-1">Our Services</h1>
</div>
</div>
</div>


      {/* <header className="header-2 ">
        <div className="page-header min-vh-100 relative" 
        style={{backgroundImage: 'url("../assets/img/services/block_chain.jpg")'}}>
          <span className="mask bg-gradient-primary opacity-5" />
          <div className="container">
            <div className="row ">
              <div className="col-lg-10 text-left mt-5" style={{backgroundColor: 'rgb(20 20 20 / 60%)',borderRadius:"10px"}}>
               
                <h1 style={{fontFamily:'revert',
               
                
                }} className="text-white pt-3  display-1">Our Services</h1>
                <p className="lead text-white mt-3 " style={{fontSize:'1.1em'}}>

               Block Chain is a technology that allows someone to create a record of something that happened in the past. The information in the block chain can not be changed, and no one on the block chain will be able to disagree with the written information. A block chain is a transaction database shared by all nodes participating in a system based on the Bitcoin protocol. A full copy of a currency's block chain contains every transaction ever executed in the currency.
 </p>
              </div>
            </div>
          </div>
        </div>
      </header> */}

     <section className="service-grid pb-5 pt-5 mt-7" >
        <div className="container">
      
          <div className="row">
            <div className="col-lg-4 col-md-6 text-center mb-3">
            <a href='../digital-trasformation' >
              <div className="service-wrap">
              
                <h4>Digital Transformation</h4>
                <p>

                
                Digital transformation is the use of digital technologies in a strategic way in order to create value.. 
                
                </p>
               
              </div>
              </a>
            </div>
            <div className="col-lg-4 col-md-6 text-center mb-3">
                <a href='../it-infrastructure-solutions' >
              <div className="service-wrap">
               
                <h4 className='redclass'>IT Infrastructure Solutions</h4>
                <p>
                
   
                We offer a wide spectrum of IT Infrastructure Services in the form of infrastructure design, implementation...
                </p>
              </div></a>
            </div>
            <div className="col-lg-4 col-md-6 text-center mb-3">
             <a href='../cloud-solutions' >
              <div className="service-wrap">
               
                <h4 className='freenclass'>Cloud Solutions</h4>
                <p>
                
          
                Cloud Solutions and DevOps provides computing services to individuals, businesses, and organizations that need...
                </p>
              </div>
              </a>
            </div>

             <div className="col-lg-4 col-md-6 text-center mb-3">
             <a href='../cyber-security-solutions' >
              <div className="service-wrap">
               
                <h4 className='blueclass'>Cyber Security Solutions</h4>
                 <p>
                
                
                Cyber Security Solutions is your company's one-stop shop for security solutions.We can help you reduce risk...
                </p>
              </div></a>
            </div>


                                     <div className="col-lg-4 col-md-6 text-center mb-3">
                         <a href='../professional-services' >
              <div className="service-wrap">
               
                <h4 className='orangeclass' >Professional Services</h4>
               <p>
                

                Professional Services provides a broad range of services in several specialized areas that can help you to get...
                </p>
              </div>
              </a>
            </div>

            

             <div className="col-lg-4 col-md-6 text-center mb-3">
             <a href='../QA-and-testing' >
              <div className="service-wrap">
               
                <h4 className='orangeclass' >QA & Testing</h4>
               <p>
              
                Testing has always been an integral part of any software development process. It's a crucial stage where defects...
                </p>
              </div>
              </a>
            </div>

             <div className="col-lg-4 col-md-6 text-center mb-3">
             <a href='../data-science-and-analysis-services' >
              <div className="service-wrap">
               
                <h4>Data Science & Analysis Services</h4>
               <p>
                

                Data Science is the interdisciplinary field concerned with the catching and understanding of data, including...
                </p>
              </div>
              </a>
            </div>

             <div className="col-lg-4 col-md-6 text-center mb-3">
             <a href='../robotics-process-automation' >
              <div className="service-wrap">
               
                <h4 className='redclass'>Robotics Process Automation</h4>
                 <p>

                The company at Advent Biztech Solutions Pvt. Ltd. is committed to providing a better user experience through...
                </p>
              </div> </a>
            </div>

             <div className="col-lg-4 col-md-6 text-center mb-3">
             <a href='../block-chain' >
              <div className="service-wrap">
               
                <h4 className='freenclass'>Block Chain</h4>
                <p>
                
 
                Block Chain is a technology that allows someone to create a record of something that happened in the past...
                </p>
              </div></a>
            </div>

             <div className="col-lg-4 col-md-6 text-center mb-3">
             <a href='../virtual-reality' >
              <div className="service-wrap">
               
                <h4 className='blueclass' >Augmented & Virtual Reality Development Centers</h4>
                <p>

                Augmented & Virtual Reality Development Centers are a perfect first step to learning augmented...
                </p>
              </div>
              </a>
            </div>

             <div className="col-lg-4 col-md-6 text-center mb-3">
             <a href='../embedded-systems-design' >
              <div className="service-wrap">
               
                <h4 className='orangeclass' >Embedded Systems Design</h4>
                <p>
                
                
                Embedded Systems Design is a specialization that focuses on the development of complex embedded systems...
                </p>
              </div>
              </a>
            </div>

             <div className="col-lg-4 col-md-6 text-center mb-3">
             <a href='../sap-solutions' >
              <div className="service-wrap">
               
                <h4>Sap Solutions & Erp Services</h4>
                <p>
                
                SAP solutions offer you the opportunity to easily and cost effectively streamline your business processes. We provide...
                
                </p>
              </div>
              </a>
            </div>

             <div className="col-lg-4 col-md-6 text-center mb-3">
             <a href='engineering' >
              <div className="service-wrap">
               
                <h4 className='redclass' >Engineering and R&D</h4>
               <p>
                
                Engineering and research and development are core capabilities that give brands the edge in the hypercompetitive...
                </p>
              </div>
              </a>
            </div>

             <div className="col-lg-4 col-md-6 text-center mb-3">
             <a href='../industrial-automation' >
              <div className="service-wrap">
               
                <h4 className='freenclass' >Industrial automation</h4>
                 <p>
                
                Our Industrial Automation solutions help you automate your business, while enabling productivity and efficiency...
                
                </p>
              </div>
              </a>
            </div>

             <div className="col-lg-4 col-md-6 text-center mb-3">
             <a href='../ites' >
              <div className="service-wrap">
               
                <h4 className='blueclass' >ITES</h4>
                <p>
                
                ITES Processes and Services supports the needs of our customers by offering a wide range of services and solutions...
                
                </p>
              </div>
              </a>
            </div>


           
          </div>
        </div>
      </section>


      <Footer/>

    </>


    
  )
}
