import React,{useState} from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import { Link, useNavigate } from "react-router-dom";

export default function Blog() {



   const [showloading,setShowloading] = useState(false);

   const [loginlist,setLoginlist] = useState([]);

      const  getmenudata = async() => { 

        setShowloading(true)  

    

       fetch("https://api.adventbiztech.com/bloglistweb.php",            {
                  method: 'GET',   
               
                  headers: {
                    'Accept': 'application/json',     
                  
                  },
                
                }).then((response) => response.text())
                  .then((response) => {
                          
                     
                     setShowloading(false)

                  
                    

                      

                          setLoginlist(JSON.parse(response))
                       

                       

                    
                          
                         




                        setShowloading(false)
       
                  }).catch(error => {
                         setShowloading(false)
                         
                             alert(error)
                           
                })

    }


           React.useEffect(() => 
  {
      document.title="Blog | AdventBiz Tech"

      //document.body.style.backgroundImage = "url('assets/img/services.jpg')";
   getmenudata()
     
  }, [])


  return (
    
    <>

      {showloading ?
         <>
        <div style={{
    background: '#000000b3',
    position: 'absolute',
    top: '0',
    left: '0',
    textAlign:'center',
    width: '100%',
    height: '220vh',
    zIndex: '99999'
 

}}>

<div style={{height:'100px',marginTop: '20%',textAlign:'center'}}>
<img src='../assets/img/128x128.gif' style={{height:'100px'}}  alt='s' />
<br/> <br/>

<span style={{color:'#dedede',width:'100px',height:'100px',marginTop: '20%',fontSize:'medium'}} > Please wait.....</span> <br/>

</div>

</div>
       </>
         :null}

    <Header/>
    <div className="container">
            <div className="row ">
              <div className="col-lg-12 text-center mt-8" >
               
       {/* <h1 style={{fontFamily:'revert'}} className="text-white pt-3 mt-8  display-1">Blog</h1> */}
</div>
</div>
</div>


   <section className="py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h3 className="mb-3" style={{fontFamily:'revert'}}>Latest blogposts</h3>
            </div>
          </div>
          <div className="row">

            {

       loginlist.map((menulist)=>{                            
                              return (
                                <>
                               
            <div className="col-lg-3 col-sm-6">
              <div className="card card-plain " style={{backgroundColor:'white'}}>
                <div className="card-header p-0 position-relative">
                  <a className="d-block blur-shadow-image">
                    <img src={"http://api.adventbiztech.com/"+menulist.img} alt="img-blur-shadow" className="img-fluid shadow border-radius-lg" loading="lazy" />
                  </a>
                </div>
                <div className="card-body px-3">
                  <h5>
                    <a href="javascript:;" className="text-dark font-weight-bold" style={{fontFamily:'revert'}}>{menulist.head}</a>
                  </h5>
                    
                    <Link to={"/singleblog"}  state={{lginid:menulist.blog_id,manager:menulist.head}} className="text-info text-sm icon-move-right"  >Read More
                    <i className="fas fa-arrow-right text-xs ms-1" aria-hidden="true" /></Link>

                  {/* <Link to={"../singleblog/"+menulist.blog_id} className="text-info text-sm icon-move-right">Read More
                    <i className="fas fa-arrow-right text-xs ms-1" aria-hidden="true" />
                  </Link> */}
                </div>
              </div>
            </div>

              </>

        )})}
           
          </div>
        </div>
      </section>


      <Footer/>

    </>


    
  )
}
