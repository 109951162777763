import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

export default function QAandtesting() {

         React.useEffect(() => 
  {
      document.title="QA & Testing | AdventBiz Tech"

   
     
  }, [])


  return (

        <>
        <Header/>
  <header className="header-2 ">
        <div className="page-header min-vh-100 relative" 
        style={{backgroundImage: 'url("../assets/img/services/QA_and_testing.jpg")'}}>
          <span className="mask bg-gradient-primary opacity-5" />
          <div className="container">
            <div className="row ">
              <div className="col-lg-10 text-left mt-5" style={{backgroundColor: 'rgb(20 20 20 / 60%)',borderRadius:"10px"}}>
               
                <h1 style={{fontFamily:'revert',
               
                
                }} className="text-white pt-3  display-1">QA & Testing</h1>
                <p className="lead text-white mt-3 " style={{fontSize:'1.1em'}}>

                Testing has always been an integral part of any software development process. It's a crucial stage where defects are found, documented and resolved. QA & Testing offers help with MySQL , Oracle and Microsoft Technologies. Helping to solve queries, improve performance of your existing setups and design new databases for improved functionality. Help us make the web a safer place to be and question everything. Each of us can help keep the internet a better and safer place. So let's get going! </p>
              </div>
            </div>
          </div>
        </div>
      </header>


       <div className='container pt-5'>       

        {/* <div class="row mt-5">
      <div class="col-lg-12 mx-auto">
        <div class="p-3 text-center mb-2">
         
          <h2 class="mt-3">Check out what&#39;s in Digital Transformation</h2>
        
        </div>
      </div>
    </div> */}


       <div className="row mt-lg-5 mt-4">
        <div className="col-lg-6 col-md-6 mb-5">
        
          <div className="card">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl">
                {/* <i className="material-icons opacity-10">manage_accounts</i> */}
                <span  className='myicons' >F</span>
            
               
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Functional Testing</h5>
              <p className="mb-3">Regression testing, User interface & Usability Testing <br/>Functional testing ensures that the software you are developing is correct and correctable, based on its intended use. Functional testing is the process of finding out whether your software will make your product function as planned. It ensures that each user can make use of your application, and it examines every data point to ensure that nothing is missing. Our experienced team will take the time to understand your needs and help you design that works best for you.</p>
              {/* <a href="javascript:;" className="font-weight-bold text-xs text-uppercase font-weight-bolder text-primary icon-move-right">
                Check more
                <i className="fas fa-arrow-right text-xs ms-1" aria-hidden="true" />
              </a> */}
            </div>
          </div>
        </div>
      



                   <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-danger shadow-danger shadow text-center border-radius-xl">
                 <span  className='myicons' >N</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Non-Functional Testing </h5>
              <p className="mb-3"> Installation Testing, Load & Performance testing , Security Testing  
<br/>Non-functional testing is a part of all software testing activities. It helps to ensure that the application meets its functional requirements, as well as maintainability and performance. Non-functional testing takes place after the final stages of development. It is a broad test that seeks to ensure that all of the functions can be completed and there are no defects in them.
                </p>
            </div>
          </div>
        </div>    


        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-success shadow-success shadow text-center border-radius-xl">
                 <span  className='myicons' >S</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Specialized Testing Services</h5>
              <p className="mb-3">Mobile App Testing , game testing , E- Governance testing API testing , Cross Browser testing , localization / Globalization testing , SEO Testing . <br/>The Specialist Testing Services division offers state-of-the-art analysis, testing and data management tools. Our experts use their knowledge and expertise to develop customized solutions for your individual challenges. We understand that the best way to improve your productivity, efficiency and results is through continuous workflow improvements and process automation.
</p>
              
            </div>
          </div>
        </div>



        
        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-info shadow-info shadow text-center border-radius-xl">
               <span  className='myicons' >T</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Test Automation</h5>
              <p className="mb-3">Mobile, Web & Cloud <br/>
Automating the testing process helps you to identify and correct issues faster, saves resources and money. A cloud-based platform with comprehensive test coverage analysis for any type of application. Automated functional and integration testing to ensure the highest possible quality and reliability of your application.
</p>
             
            </div>
          </div>
        </div>


        
        



  









      </div>
</div>    

        <Footer/>
        </>
    
  )
}
