import React,{useState} from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import { Link, useNavigate } from "react-router-dom";

export default function Careers() {

     const [showloading,setShowloading] = useState(false);

   const [loginlist,setLoginlist] = useState([]);

      const  getmenudata = async() => { 

        setShowloading(true)  

    

       fetch("https://api.adventbiztech.com/contentlistweb.php",            {
                  method: 'GET',   
               
                  headers: {
                    'Accept': 'application/json',     
                  
                  },
                
                }).then((response) => response.text())
                  .then((response) => {
                          
                     
                     setShowloading(false)

                  
                    

                      

                          setLoginlist(JSON.parse(response))
                       

                       

                    
                          
                         




                        setShowloading(false)
       
                  }).catch(error => {
                         setShowloading(false)
                         
                             alert(error)
                           
                })

    }

    React.useEffect(() => 
  {
      document.title="Careers | AdventBiz Tech"     

      getmenudata()
  }, [])

  return (
   
    
      <>


        {showloading ?
         <>
        <div style={{
    background: '#000000b3',
    position: 'absolute',
    top: '0',
    left: '0',
    textAlign:'center',
    width: '100%',
    height: '220vh',
    zIndex: '99999'
 

}}>

<div style={{height:'100px',marginTop: '20%',textAlign:'center'}}>
<img src='../assets/img/128x128.gif' style={{height:'100px'}}  alt='s' />
<br/> <br/>

<span style={{color:'#dedede',width:'100px',height:'100px',marginTop: '20%',fontSize:'medium'}} > Please wait.....</span> <br/>

</div>

</div>
       </>
         :null}


    <Header/>

  <header className="header-2 ">
        <div className="page-header min-vh-100 relative" style={{backgroundImage: 'url("../assets/img/close-up-briefcase.jpg")'}}>
          <span className="mask bg-gradient-primary opacity-5" />
          <div className="container mycareerdiv">

          <h1 style={{fontFamily:'revert',color:'white'}}>Careers</h1>
            <div className="row  make-columns">

              {

       loginlist.map((menulist)=>{                            
                              return (
                                <>

                  <div className="col-lg-4 text-left" >

                    <div className="mycards panel">
                        <div className="content">
                          <h3 style={{fontFamily:'revert'}} >{menulist.heading}</h3>
                          <p>
                          <span style={{fontFamily:'revert'}} dangerouslySetInnerHTML={ { __html: menulist.content } }></span>
                          </p>
                          <Link to={"/careersingle"}  state={{lginid:menulist.heading,manager:menulist.content}} className="text-info text-sm icon-move-right"  >
                 
                          <span style={{backgroundColor:'red',color:'#fff', paddingTop:'5px',paddingBottom:'5px',paddingRight:'10px',paddingLeft:'10px',borderRadius:'5px'}}> Apply Now</span>
                          </Link>
                         
                          </div>
                    </div>
                          
               
                     </div>
 </>

        )})}
             
            </div>
          </div>
        </div>
      </header>







    <Footer/>

    </>
  )
}
