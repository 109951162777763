import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

export default function Embeddedsystemsdesign() {

         React.useEffect(() => 
  {
      document.title="Embedded Systems Design | AdventBiz Tech"

   
     
  }, [])


  return (

        <>
        <Header/>
  <header className="header-2 ">
        <div className="page-header min-vh-100 relative" 
        style={{backgroundImage: 'url("../assets/img/services/embedded_systems_design.jpg")'}}>
          <span className="mask bg-gradient-primary opacity-5" />
          <div className="container">
            <div className="row ">
              <div className="col-lg-10 text-left mt-5" style={{backgroundColor: 'rgb(20 20 20 / 60%)',borderRadius:"10px"}}>
               
                <h1 style={{fontFamily:'revert',
               
                
                }} className="text-white pt-3  display-1">Embedded Systems Design</h1>
                <p className="lead text-white mt-3 " style={{fontSize:'1.1em'}}>

                Embedded Systems Design is a specialization that focuses on the development of complex embedded systems. Students learn how to design and implement computer components such as microprocessors, sensors and actuators within a system’s architecture. Students also encounter real-world applications like medical devices –from pacemakers to artificial hearts–which require their expertise.
The Embedded Systems Design class serves as an introduction to the principles of embedded systems design, exploration of designs for specific applications, and provides a thorough grounding in the fundamentals of embedded systems programming. The course material will cover all aspects of this rapidly growing field and introduce students to basic concepts, tools, techniques and thought processes in designing embedded systems.

 </p>
              </div>
            </div>
          </div>
        </div>
      </header>


       <div className='container pt-5'>       

        {/* <div class="row mt-5">
      <div class="col-lg-12 mx-auto">
        <div class="p-3 text-center mb-2">
         
          <h2 class="mt-3">Check out what&#39;s in Digital Transformation</h2>
        
        </div>
      </div>
    </div> */}


       <div className="row mt-lg-5 mt-4">
        <div className="col-lg-6 col-md-6 mb-5">
        
          <div className="card">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl">
                {/* <i className="material-icons opacity-10">manage_accounts</i> */}
                <span  className='myicons' >H</span>
            
               
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Hardware Design services</h5>
              <p className="mb-3">Hardware Design services are essential for most business operations. As the custom development of hardware products creates unique challenges, new products must be designed with a high level of flexibility and future-proofness. We help you design, prototype and test your next product in a matter of weeks, so project teams can focus on what matters most: innovation and growth. Our hardware design services help you get your product to market faster. We’ll focus on design, prototyping and manufacturing, identifying all of the best practices that can help you stand out from the crowd. We use our experience to guide you towards the solution that will work for your business. 
</p>
              {/* <a href="javascript:;" className="font-weight-bold text-xs text-uppercase font-weight-bolder text-primary icon-move-right">
                Check more
                <i className="fas fa-arrow-right text-xs ms-1" aria-hidden="true" />
              </a> */}
            </div>
          </div>
        </div>
      



                   <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-danger shadow-danger shadow text-center border-radius-xl">
                 <span  className='myicons' >D</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Design to Manufacture</h5>
              <p className="mb-3">We know that finding the right fabric is an important part of your whole process. You want your final product to look great, and you want it to be made in a way that gets you the best results on time and on budget. That's where we come in. We'll design with you to create a cutting pattern that makes sense, then get right to work with our quality control team to ensure both sides of the process are just as important. To design, invent and manufacture complete products according to a unique requirement or use. The process is done by the design stage which can be done by using analytical methodologies like market research, user study and contextual inquiry etc.</p>
             
            </div>
          </div>
        </div>    

  <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-success shadow-success shadow text-center border-radius-xl">
                 <span  className='myicons' >E</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Embedded System & Software Development</h5>
              <p className="mb-3">Embedded systems are any system that combines software and hardware in a single physical device. This field of study is developing at an incredible rate, with new devices and applications appearing every day. With so many different architectures out there, as well as changing requirements, it can be difficult to decide on the correct strategy for building an embedded system. Our team of experts in embedded software development, HDL design, and hardware enable you to take your project from concept to reality. Whether you're a startup looking to launch an innovative product or a Fortune 500 company seeking rapid expansion, we'll help you deliver a solution that exceeds expectations.</p>
              
            </div>
          </div>
        </div>



        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-info shadow-info shadow text-center border-radius-xl">
                 <span  className='myicons' >M</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Multimedia Digital solutions</h5>
              <p className="mb-3">Multimedia Digital Solutions is a multi discipline media company offering integrated digital marketing solutions. Our unique approach of combining the best of internet, mobile and social media to help small businesses engage current customers in new ways and grow their business via smart digital marketing strategies. From design to implementation, we have an expert team that excels at every stage of digital transformation. We are a full service digital agency. Our clients can trust us as they partner with us on their project along the entire lifecycle of development, deployment and maintenance of their digital solution. 
</p>
              
            </div>
          </div>
        </div>



        
   
  









      </div>
</div>    

        <Footer/>
        </>
    
  )
}
