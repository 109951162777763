import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

export default function Virtualreality() {

         React.useEffect(() => 
  {
      document.title="Augmented & Virtual Reality Development Centers | AdventBiz Tech"

   
     
  }, [])


  return (

        <>
        <Header/>
  <header className="header-2 ">
        <div className="page-header min-vh-100 relative" 
        style={{backgroundImage: 'url("../assets/img/services/virtual_reality.jpg")'}}>
          <span className="mask bg-gradient-primary opacity-5" />
          <div className="container">
            <div className="row ">
              <div className="col-lg-10 text-left mt-5" style={{backgroundColor: 'rgb(20 20 20 / 60%)',borderRadius:"10px"}}>
               
                <h1 style={{fontFamily:'revert',
               
                
                }} className="text-white pt-3  display-1">Augmented & Virtual Reality Development Centers</h1>
                <p className="lead text-white mt-3 " style={{fontSize:'1.1em'}}>

                Augmented & Virtual Reality Development Centers are a perfect first step to learning augmented and virtual reality development. In these courses students will learn how to create interactive experiences, build cross-platform applications, test their projects and much more. Students will also learn what is needed to be an Augmented or Virtual Reality developer.
 </p>
              </div>
            </div>
          </div>
        </div>
      </header>


       <div className='container pt-5'>       

        {/* <div class="row mt-5">
      <div class="col-lg-12 mx-auto">
        <div class="p-3 text-center mb-2">
         
          <h2 class="mt-3">Check out what&#39;s in Digital Transformation</h2>
        
        </div>
      </div>
    </div> */}


       <div className="row mt-lg-5 mt-4">
        <div className="col-lg-6 col-md-6 mb-5">
        
          <div className="card">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl">
                {/* <i className="material-icons opacity-10">manage_accounts</i> */}
                <span  className='myicons' >V</span>
            
               
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Virtual Reality</h5>
              <p className="mb-3">Virtual reality is becoming a new part of our everyday lives. In the past, it was hard to imagine having a virtual experience without going to some place and experiencing it first hand. With technology advancing at an ever-increasing pace, it seems that sooner than we think, we will be living in a fully immersive virtual living environment. Along with the growth of technology and connectivity, our experience of the world around us has been revolutionized. From virtual to augmented reality and beyond, we look at what makes this trend so significant for your business.
</p>
              {/* <a href="javascript:;" className="font-weight-bold text-xs text-uppercase font-weight-bolder text-primary icon-move-right">
                Check more
                <i className="fas fa-arrow-right text-xs ms-1" aria-hidden="true" />
              </a> */}
            </div>
          </div>
        </div>
      



                   <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-danger shadow-danger shadow text-center border-radius-xl">
                 <span  className='myicons' >A</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Augmented Reality</h5>
              <p className="mb-3">Augmented Reality is a concept of computer-generated sensory input that has a definite physical presence in the real world, though not necessarily visible to the unaided human eye. While machine vision and database technologies enabled by sensors such as accelerometers and gyroscopes have been used for recognizing and understanding objects in the real world, these methods are limited from a computational perspective by requiring both an enormous amount of data and significant processing resources at every step. AUGMENTED REALITY, INC. is a leading international business development company in the area of Augmented Reality (AR) and Virtual Reality (VR). We help companies of all sizes see an exponential growth in revenue through our worldwide partnerships with key Hollywood studios, talent and game developers.</p>
             
            </div>
          </div>
        </div>    

  <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-success shadow-success shadow text-center border-radius-xl">
                 <span  className='myicons' >W</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Web VR </h5>
              <p className="mb-3">WEB VR is a virtual reality live stream that gives viewers the ability to immerse themselves in one of the new immersive 360 videos seen on the Web. This new way of broadcasting allows users to explore unique locales and locations with the best 360 videographers around the world. We help companies of all sizes see an exponential growth in revenue through our worldwide partnerships with key Hollywood studios, talent and game developers.
</p>
              
            </div>
          </div>
        </div>



        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-info shadow-info shadow text-center border-radius-xl">
                 <span  className='myicons' >V</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">360 Videos </h5>
              <p className="mb-3">360 Videos is a simple yet powerful tool for faster, more accurate sales. Our web app allows you to easily upload files from your device to your website, display them on a flat web page or as videos embedded in articles. We have even integrated with Instagram so you can use our 360 videos as part of your Instagram feed too! 360 Videos is an easy to use and fully customizable video editing tool that allows you to add fun and creative effects to your video. You can also adjust the resolution, aspect ratio and frame rate of your video. We create innovative and compelling 360 videos for brands of all sizes. We put our experience in data-driven media and technology to work for you.
 
</p>
              
            </div>
          </div>
        </div>




   
        
   
  









      </div>
</div>    

        <Footer/>
        </>
    
  )
}
