import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

export default function Engineering() {

         React.useEffect(() => 
  {
      document.title="Engineering and R & D | AdventBiz Tech"

   
     
  }, [])


  return (

        <>
        <Header/>
  <header className="header-2 ">
        <div className="page-header min-vh-100 relative" 
        style={{backgroundImage: 'url("../assets/img/services/engineering.jpg")'}}>
          <span className="mask bg-gradient-primary opacity-5" />
          <div className="container">
            <div className="row ">
              <div className="col-lg-10 text-left mt-5" style={{backgroundColor: 'rgb(20 20 20 / 60%)',borderRadius:"10px"}}>
               
                <h1 style={{fontFamily:'revert',
               
                
                }} className="text-white pt-3  display-1">Engineering and R & D</h1>
                <p className="lead text-white mt-3 " style={{fontSize:'1.1em'}}>

                Engineering and research and development are core capabilities that give brands the edge in the hypercompetitive global markets. The ability to innovate, to develop new products or processes, and to bring them to market quickly are crucial to establishing a competitive advantage. As a member of our Engineering and R&D team, you will work with other engineers and scientists to develop new products. You will also be responsible for driving business decisions through technical analysis and consulting with customers.
 </p>
              </div>
            </div>
          </div>
        </div>
      </header>


       <div className='container pt-5'>       

        {/* <div class="row mt-5">
      <div class="col-lg-12 mx-auto">
        <div class="p-3 text-center mb-2">
         
          <h2 class="mt-3">Check out what&#39;s in Digital Transformation</h2>
        
        </div>
      </div>
    </div> */}


       <div className="row mt-lg-5 mt-4">
        <div className="col-lg-6 col-md-6 mb-5">
        
          <div className="card">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl">
                {/* <i className="material-icons opacity-10">manage_accounts</i> */}
                <span  className='myicons' >P</span>
            
               
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Product Engineering</h5>
              <p className="mb-3">Product Engineering is the broad discipline that works with engineers, designers and other practitioners to develop new products. Product engineers focus on identifying new product opportunities by analyzing customer needs, competitive dynamics and operational performance. We take immense pride in designing, developing and testing digital products that help you reach your customers at every step of their journey. We are experienced product designers who can help you achieve business goals through intuitive design principles.
</p>
              {/* <a href="javascript:;" className="font-weight-bold text-xs text-uppercase font-weight-bolder text-primary icon-move-right">
                Check more
                <i className="fas fa-arrow-right text-xs ms-1" aria-hidden="true" />
              </a> */}
            </div>
          </div>
        </div>
      



                   <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-danger shadow-danger shadow text-center border-radius-xl">
                 <span  className='myicons' >P</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Platform Engineering </h5>
              <p className="mb-3">The platform engineering process is a strategic and tactical design process that starts with an analysis of the platform's requirements and continues throughout its life cycle. A platform engineering project is an ambitious undertaking that aims to make a single computer operating system, or even more than one, more powerful by adding more resources (such as memory and CPU) and internal logic optimizations.
</p>
             
            </div>
          </div>
        </div>    

  <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-success shadow-success shadow text-center border-radius-xl">
                 <span  className='myicons' >O</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Operational Technology Services</h5>
              <p className="mb-3">We understand that your business is constantly in motion and that you need to stay on top of the latest technologies. That’s why we offer industry leading operational technology services that help you drive efficiency and lower running costs. Operational technology services companies can help you develop, build and manage operational technology. They have in-depth expertise on the use of technology to improve operational efficiency and productivity while reducing costs.
</p>
              
            </div>
          </div>
        </div>



        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-warning shadow-warning shadow text-center border-radius-xl">
                 <span  className='myicons' >N</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Next Gen Engineering</h5>
              <p className="mb-3">In the same way that our first gen engineering allowed us to create a more efficient dining experience and the newest edition of the Live it Up collection is redefining what’s possible while raising the bar, we’ve used new materials to build upon our original innovations. The result: next gen engineering. We are a professional engineering service company that offers services such as design, engineering, fabrication and prototyping. We specialize in creating innovative products using 3D printing technology to develop new solutions for our clients.

</p>
              
            </div>
          </div>
        </div>



          <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-info shadow-info shadow text-center border-radius-xl">
                 <span  className='myicons' >P</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">PLM</h5>
              <p className="mb-3">PLM gives companies the tools to make strategic and tactical business decisions by acting as a single source of truth for all enterprise data. We have developed advanced data sources and analytics capabilities, deep integrations with external systems and applications, and powerful dashboards that allow users to make informed decisions out of the box. PLM (Manufacturing) is a term used in the industry and means that the product, from idea through manufacturing and delivery, will be fully integrated. Developing an accurate product catalog using third-party data is an important part of building a successful PLM implementation. Existing products can be updated or upgraded and new products can be developed to meet specific customer needs.

</p>
              
            </div>
          </div>
        </div>




  
   
  









      </div>
</div>    

        <Footer/>
        </>
    
  )
}
