import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

export default function Industrialautomation() {

         React.useEffect(() => 
  {
      document.title="Industrial automation | AdventBiz Tech"

   
     
  }, [])


  return (

        <>
        <Header/>
  <header className="header-2 ">
        <div className="page-header min-vh-100 relative" 
        style={{backgroundImage: 'url("../assets/img/services/industrial_automation.jpg")'}}>
          <span className="mask bg-gradient-primary opacity-5" />
          <div className="container">
            <div className="row ">
              <div className="col-lg-10 text-left mt-5" style={{backgroundColor: 'rgb(20 20 20 / 60%)',borderRadius:"10px"}}>
               
                <h1 style={{fontFamily:'revert',
               
                
                }} className="text-white pt-3  display-1">Industrial Automation</h1>
                <p className="lead text-white mt-3 " style={{fontSize:'1.1em'}}>

                Our Industrial Automation solutions help you automate your business, while enabling productivity and efficiency. Industrial automation systems are designed to handle repetitive tasks and are engineered for reliability and performance. The technology is used in areas such as automotive, food processing, pharmaceutical, nuclear power generation and large scale manufacturing facilities.
 </p>
              </div>
            </div>
          </div>
        </div>
      </header>


       <div className='container pt-5'>       

        {/* <div class="row mt-5">
      <div class="col-lg-12 mx-auto">
        <div class="p-3 text-center mb-2">
         
          <h2 class="mt-3">Check out what&#39;s in Digital Transformation</h2>
        
        </div>
      </div>
    </div> */}


       <div className="row mt-lg-5 mt-4">
        <div className="col-lg-6 col-md-6 mb-5">
        
          <div className="card">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl">
                {/* <i className="material-icons opacity-10">manage_accounts</i> */}
                <span  className='myicons' >P</span>
            
               
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">PLC, SCADA & HMI Engineering</h5>
              <p className="mb-3">Staff your team with the talent and expertise you need, when you need it. We’ll provide our engineers onsite for a quick response, instead of hiring a contractor or diverting resources from other parts of your business. Our onsite services go beyond technology stacks; we also help you build highly predictable and efficient operations so you can spend more time making money, less time battling behind-the-scenes issues. Our team of engineers are available to discuss your unique project requirements, or you can book a meeting with one of our senior engineers by clicking on the Contact Us button to discuss your requirements and how we can help you achieve your goals.
</p>
              {/* <a href="javascript:;" className="font-weight-bold text-xs text-uppercase font-weight-bolder text-primary icon-move-right">
                Check more
                <i className="fas fa-arrow-right text-xs ms-1" aria-hidden="true" />
              </a> */}
            </div>
          </div>
        </div>
      



                   <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-danger shadow-danger shadow text-center border-radius-xl">
                 <span  className='myicons' >D</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">DCS Engineering</h5>
              <p className="mb-3">DCS Engineering is a company that provides quality engineering services. We provide project planning and management, technical assistance and training. Our ranks include a solid foundation of experience with proven track records in developing, executing, and managing complex information technology projects on time, within budget, and to scope. We develop world class solutions that help organizations drive growth and innovation. Our vision is to be the strategic partner of choice for our clients, providing intelligent and effective solutions to their business needs
</p>
             
            </div>
          </div>
        </div>    

  <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-success shadow-success shadow text-center border-radius-xl">
                 <span  className='myicons' >S</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Safety Systems & Drives Engineering</h5>
              <p className="mb-3">Safety systems & drives engineering is a branch of engineering which is concerned with the design and development of safety systems, such as aircraft, motor vehicles and industrial machinery to protect people from harmful effects.Safety systems Engineering may include safety strategy, management system and risk assessment. A major role of this is in the process of designing safe products, especially those related to control of human factors (such as designing software for a system that has been tested), ensuring that there are no gaps in the process, or gaps between design and manufacturing when creating these products.
</p>
              
            </div>
          </div>
        </div>



        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-info shadow-info shadow text-center border-radius-xl">
                 <span  className='myicons' >C</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">CNC, Motion & Robotics Engineering </h5>
              <p className="mb-3">We are the best Place for CNC, Motion & Robotics Engineering. We have a team of experts who have more than 4 years of experience in this field and can give you an opportunity to work on real projects. Please come and visit us. These fields are a great complement to each other, as motion and robotics engineering can help develop more advanced robotic systems. CNC machining and robotics are often quite similar, but there are some fundamental differences between the two. In general, where robotics focuses on various aspects of operation, motion focuses on components such as cameras, sensors and motors. Provides access to highly qualified and experienced engineers, technical support and talent management tools.

</p>
              
            </div>
          </div>
        </div>



          <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-danger shadow-danger shadow text-center border-radius-xl">
                 <span  className='myicons' >D</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Documentation & Specifications (FDS/ DDS)</h5>
              <p className="mb-3">Documentation & Specifications (FDS/ DDS) is the first level of testing performed on assemblies before they are released to manufacturing. The manufacturing vendor performs a Quality Review of assembly files submitted by the customer, prior to design acceptance. Any issues found require a response by the customer and/or production vendor, which may result in changes to the assembly file, modifications to processes and/or rework to the original components or sub-assemblies. Documentation and specifications are key components of your product design process. They’re used to create a robust design, define quality requirements and ensure that the end product meets all of its stated requirements in advance of manufacturing.


</p>
              
            </div>
          </div>
        </div>



 <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-xl">
                 <span  className='myicons' >P</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Pharmaceutical Validation Services </h5>
              <p className="mb-3">Pharmaceutical Validation Services helps manufacturers to meet the requirements of FDA and other safety authorities by the validation of product safety, quality, and performance. Our services are customized to your specific needs, and give you a competitive edge when competing with imports. We provide products that perform above international standards without sacrificing quality. Our Pharmaceutical Validation Services help ensure the safety, efficacy and quality of a product before it reaches patients. We offer validation services in drug development, manufacturing, and analytical testing, as well as drug approval.


</p>
              
            </div>
          </div>
        </div>



  
   
  









      </div>
</div>    

        <Footer/>
        </>
    
  )
}
