import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

export default function Cybersecuritysolutions() {

         React.useEffect(() => 
  {
      document.title="Cyber Security Solutions | AdventBiz Tech"

   
     
  }, [])


  return (

        <>
        <Header/>
  <header className="header-2 ">
        <div className="page-header min-vh-100 relative" 
        style={{backgroundImage: 'url("../assets/img/services/cyber_security_solutions.jpg")'}}>
          <span className="mask bg-gradient-primary opacity-5" />
          <div className="container">
            <div className="row ">
              <div className="col-lg-10 text-left mt-5" style={{backgroundColor: 'rgb(20 20 20 / 60%)',borderRadius:"10px"}}>
               
                <h1 style={{fontFamily:'revert',
               
                
                }} className="text-white pt-3  display-1">Cyber Security Solutions</h1>
                <p className="lead text-white mt-3 " style={{fontSize:'1.1em'}}>

              Cyber Security Solutions is your company's one-stop shop for security solutions. We can help you reduce risk, enhance user functionality, and improve the overall success of your business. With a team of specialists at our fingertips, we have the knowledge and experience to guide you through each step in the process. Whether you need assistance with identifying risks or need a customized solution that fits your needs, we can help. </p>
              </div>
            </div>
          </div>
        </div>
      </header>


       <div className='container pt-5'>       

        {/* <div class="row mt-5">
      <div class="col-lg-12 mx-auto">
        <div class="p-3 text-center mb-2">
         
          <h2 class="mt-3">Check out what&#39;s in Digital Transformation</h2>
        
        </div>
      </div>
    </div> */}


       <div className="row mt-lg-5 mt-4">
        <div className="col-lg-6 col-md-6 mb-5">
        
          <div className="card">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl">
                {/* <i className="material-icons opacity-10">manage_accounts</i> */}
                <span  className='myicons' >E</span>
            
               
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Enterprise Security</h5>
              <p className="mb-3">We are a security provider that provides information on what's going on in your organization, using an intuitive interface. Our customers use it to be better informed, collaborate effectively and make better decisions. We also offer automated processes that will manage security configuration and management, giving you peace of mind knowing that your systems are protected.</p>
              {/* <a href="javascript:;" className="font-weight-bold text-xs text-uppercase font-weight-bolder text-primary icon-move-right">
                Check more
                <i className="fas fa-arrow-right text-xs ms-1" aria-hidden="true" />
              </a> */}
            </div>
          </div>
        </div>
      



                   <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-danger shadow-danger shadow text-center border-radius-xl">
                 <span  className='myicons' >N</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2"> Network Security</h5>
              <p className="mb-3">Network Security is a rapidly growing company focused on providing affordable network security software to businesses around the world. Our goal is to help customers worldwide protect their data from cyber attacks and malware, so they can focus on running their business.
                </p>
            </div>
          </div>
        </div>    

  <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-success shadow-success shadow text-center border-radius-xl">
                 <span  className='myicons' >A</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Application Security</h5>
              <p className="mb-3">Application Security is a complete solution that covers every aspect of application security assessment, risk management and digital transformation. We provide a suite of solutions that work seamlessly together to help organizations mitigate risks and accelerate digital transformations, ensure compliance with regulations around the world and protect the integrity of their data.

                </p>
            </div>
          </div>
        </div>



        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-xl">
                 <span  className='myicons' >I</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Data Security</h5>
              <p className="mb-3">The Data Security mission is to make sure that companies, whether large or small, are providing their employees with the tools they need to be productive and safe. Currently, many companies are using outdated security policies that provide little in terms of operational efficiency and zero protection for their passwords. Using strong passwords and a secure password manager has helped countless businesses protect their data.
</p>
              
            </div>
          </div>
        </div>



        
        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-info shadow-info shadow text-center border-radius-xl">
               <span  className='myicons' >C</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Cloud Security</h5>
              <p className="mb-3">We help companies protect critical information assets by ensuring they have the right information security solutions in place. Our custom developed Security Advisory Service enables organizations to determine what information security controls are best for their needs, how to implement them, and how to monitor their progress. By working hand-in-hand with clients from start to finish, we help them achieve integrated, on-going security solutions.

</p>
             
            </div>
          </div>
        </div>


        
  
   



  









      </div>
</div>    

        <Footer/>
        </>
    
  )
}
