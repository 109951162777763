import React,{useState} from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import { useNavigate , useLocation } from 'react-router-dom'
import axios from 'axios';
 import Swal from 'sweetalert2'

export default function Careersingle() {

    const location = useLocation();

      const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

    React.useEffect(() => 
  {
      document.title="Careers Enquiry | AdventBiz Tech"

   
     
  }, [])


  const [fileext, setfileext] = useState("");
               const [fileupload1active, setfileupload1active] = useState("0");
    const [filebase641, setfilebase641] = useState("../assets/img/user_icon.png");


       const fileChangedHandler1 = (e) => {   
            let file_size = e.target.files[0].size;
            let file_name = e.target.files[0].type;
             
            let getinfo=e.target.files[0].name.split('.')
           
          

            if(getinfo[1]==="pdf" || getinfo[1]==="doc" || getinfo[1]==="docx")
            {

              setfileext(getinfo[1])
              setfileupload1active("2")
           

          // let blobURL = URL.createObjectURL(e.target.files[0]);

           //document.querySelector("myvideo").src = blobURL;

          
           // document.getElementById("myvideo").src= blobURL;

          
            if((parseInt(file_size)/1024) < 20000)
            {

              setfileupload1active("2")

                 var fileToLoad = e.target.files[0];

                // seturl(e.target.files[0])
                
                 //alert(String(e.target.files[0].value))
               var fileReader = new FileReader();
           
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {          
              
              
                setfileupload1active("2")
                setfilebase641(fileLoadedEvent.target.result)
                // Print data in console
              
            };
            // Convert data to base64
              fileReader.readAsDataURL(fileToLoad);
            }
            else{
                setfileupload1active("1")
            }


             }
            else{

              setfileupload1active("1")
              return
            }
     
        }
         const [name, setName] = useState();
    const [nameactive, setNameactive] = useState("0");

            const changename = (e) => {        
             
            if(e.target.value!=="" && e.target.value.length>1)
            { 
                /* return true */
                    setNameactive("2")
            }
            else{
                    setNameactive("1")
            }

            setName(e.target.value)
        }

                 const [mobile,setmobile]=useState("");
    const [mobileactive,setmobileactive]=useState("0");

         const changehmmobile = (e) => {   

            if(/^([56789]\d{9})$/.test(e.target.value))
            {              
                    setmobileactive("2")
            }
            else{
                    setmobileactive("1")
            }
          setmobile(e.target.value.toUpperCase())          
        }



                         const [emails,setemails]=useState("");
    const [emailsactive,setemailsactive]=useState("0");

         const changeemails = (e) => {   

            if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(e.target.value))
            {              
                    setemailsactive("2")
            }
            else{
                    setemailsactive("1")
            }
          setemails(e.target.value)          
        }


     const [showloading,setShowloading] = useState(false);

  const [setprogress, setsetprogress] = useState(0);
  let fileprocess=0;
             const  registerme1 = async() => {

           
     

          if(nameactive==="2"  && emailsactive==="2"  && mobileactive==="2" && fileupload1active==="2"  ) 
          {
           setShowloading(true)
    
           axios.post("https://api.adventbiztech.com/careermail.php",
        // axios.post("https://emailtemplate.easysoftstech.com/careermail.php",
                      JSON.stringify({name: name,
                         email: emails,
                          mobile: mobile,
                           file: filebase641,
                           fileext: fileext,
                            job: location.state.lginid,
                            jobdesc: location.state.manager,
                        
                        }),
                      {
                        headers: {
                             'Accept': 'application/json',                         
                          
                      },
                       onUploadProgress : (progressEvent) => {
      let progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      // Update state here
     
          setsetprogress(progress);
  
       fileprocess=progress;
    },
                      
      })
      .then(response => {
                          
       
         


                    if(response.data.message==='success')
                    {

                     
                        setName("")
                        setNameactive("0")
                        setemails("")
                        setemailsactive("0")
                        setmobile("")
                        setmobileactive("0")
                        setfilebase641("")
                        setfileupload1active("0")
                          
                       document.getElementById("uploadCaptureInputFile").value = "";
                   

                         Toast.fire({
                        icon: 'success',
                        title: 'Resume submitted successfully'
                      })

                    
                      
                      
                    }
                    else if(response.data.message==='error'){

                            Toast.fire({
                        icon: 'error',
                        title: 'Something went wrong. Please try again later!'
                      })
                    }

                         
                       
                         
                         
                           
                      
                          setShowloading(false)

                    


      })
      .catch(function (error) {
          setShowloading(false)
          

               alert(error)
                 
       
      });

    }
    else{

        if(nameactive==="0")
          {
            setNameactive("1");
          }

           if(emailsactive==="0")
          {
            setemailsactive("1");
          }

           if(mobileactive==="0")
          {
            setmobileactive("1");
          }

           if(fileupload1active==="0")
          {
            setfileupload1active("1");
          }

          

        
            

    }


        }

  return (
   
    
      <>

       {showloading ?
         <>
        <div style={{
    background: '#000000b3',
    position: 'absolute',
    top: '0',
    left: '0',
    textAlign:'center',
    width: '100%',
    height: '220vh',
    zIndex: '99999'
 

}}>

<div style={{height:'100px',marginTop: '20%',textAlign:'center'}}>
<img src='../assets/img/128x128.gif' style={{height:'100px'}}  alt='s' />
<br/> <br/>

<span style={{color:'#dedede',width:'100px',height:'100px',marginTop: '20%',fontSize:'medium'}} > Uploading and sending Resume.....</span> <br/>

</div>

</div>
       </>
         :null}



    <Header/>

  <header className="header-2 ">
        <div className="page-header min-vh-100 relative" style={{backgroundImage: 'url("../assets/img/close-up-briefcase.jpg")'}}>
          <span className="mask bg-gradient-primary opacity-5" />
          <div className="container">
            <div className="row ">

                  <div className="col-lg-6 text-left mt-5" >

                                        <div className="contact_form_inner" style={{background:'#fff'}}>
                        <div className="contact_field">
                            <h3 className='text-black mb-4 display-2' style={{fontFamily:'revert'}}>Careers</h3>
                            <p>We strive for a diverse and enthusiastic workplace. At Synopsys, we appreciate that there's more than one path to success, 
                            and we're always looking for talent to bring new and innovative ideas to our culture.</p>
                          <input type="text" id="fullname" value={name} className="form-control form-group" placeholder="Name" 
onChange={(e) => {changename(e)}} 

                          />
                           {nameactive!=="1" ?
                             null:<span style={{color:'red'}}>Enter Full Name</span>}
                          <input type="text" id="emailid" value={emails} className="form-control form-group" placeholder="Email" onChange={(e) => {changeemails(e)}} />
                       {emailsactive!=="1" ?
                             null:<span style={{color:'red'}}>Enter Valid Email</span>}
                           <input type="text" value={mobile} className="form-control form-group" placeholder="Mobile" onChange={(e) => {changehmmobile(e)}}  />
                           {mobileactive!=="1" ?
                             null:<span style={{color:'red'}}>Enter Valid Mobile Number</span>}
                          <div className='mt-2 mb-2' style={{}}>
                          <span  style={{fontSize: '12px',color:'#495057',lineHeight:'1.5rem',opacity:'.6',letterSpacing:'1px'}} >Upload your Resume</span>
                           <input type="file" style={{borderColor:'#fff'}} className="form-control form-group" 
                           onChange={fileChangedHandler1} id="uploadCaptureInputFile"
                           accept="application/pdf,application/msword,
                              application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                             />
                             {fileupload1active!=="1" ?
                             null:
                           <span style={{color:'red'}}>Select Valid File(eg.doc,pdf,docx)</span>
                             }
                           </div>
                          {/* <textarea className="form-control form-group" placeholder="Message" defaultValue={""} /> */}
                          <button className="contact_form_submit"  onClick={() => {registerme1()}}   >Send</button>
                        </div>
                      </div>
               
                     </div>

               <div className="col-lg-6 text-left mt-5" >

                                        <div className="contact_form_inner" style={{background:'#fff'}}>
                        <div className="contact_field">
                            <h3 className='text-black mb-4 display-2' style={{fontFamily:'revert'}}>Job Profile</h3>

                             <h6 className='text-black mb-4' style={{fontFamily:'revert'}}>{location.state.lginid}</h6>

                              <p className='text-black mb-4 display-2' style={{fontFamily:'revert'}}>
                               <span style={{fontFamily:'revert'}} dangerouslySetInnerHTML={ { __html: location.state.manager } }></span>
                              </p>
                           
                          
                      </div>
               
                     </div>
                     </div>
            </div>
          </div>
        </div>
      </header>



    <Footer/>

    </>
  )
}
