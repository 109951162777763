import React,{useState} from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import { useNavigate , useLocation } from 'react-router-dom'
import axios from 'axios';
 import { GoogleMap,useJsApiLoader ,LoadScript,InfoWindow } from '@react-google-maps/api';

 import Swal from 'sweetalert2'
 

export default function Contact() {

  const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

       React.useEffect(() => 
  {
      document.title="Contact Us | AdventBiz Tech"

   
     
  }, [])


    
           const containerStyle = {
  width: '100%',
  height: '420px'
};



const center = {
  lat: 16.88818299526406,
  lng: 75.16157217551572
}

const position = {
    lat: 16.88818299526406,
  lng: 75.16157217551572
}


const divStyle = {
  background: "white",  
  padding: 1
}

const onLoad = marker => {
  console.log('marker: ', marker)
}


// const { isLoaded } = useJsApiLoader({
//     id: 'google-map-script',
//     googleMapsApiKey: "AIzaSyCcABaamniA6OL5YvYSpB3pFMNrXwXnLwU"
    
//   })

    const [name, setName] = useState();
    const [nameactive, setNameactive] = useState("0");

    
     const [showloading,setShowloading] = useState(false);

            const changename = (e) => {        
             
            if(e.target.value!=="" && e.target.value.length>1)
            { 
                /* return true */
                    setNameactive("2")
            }
            else{
                    setNameactive("1")
            }

            setName(e.target.value)
        }


        
    const [subject, setsubject] = useState();
    const [subjectactive, setsubjectactive] = useState("0");

            const changesubject = (e) => {        
             
            if(e.target.value!=="" && e.target.value.length>1)
            { 
                /* return true */
                    setsubjectactive("2")
            }
            else{
                    setsubjectactive("1")
            }

            setsubject(e.target.value)
        }

        const [message, setmessage] = useState();

                    const changemessage = (e) => {        
             
          

            setmessage(e.target.value)
        }

        
                         const [emails,setemails]=useState("");
    const [emailsactive,setemailsactive]=useState("0");

         const changeemails = (e) => {   

            if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(e.target.value))
            {              
                    setemailsactive("2")
            }
            else{
                    setemailsactive("1")
            }
          setemails(e.target.value)          
        }


          const [setprogress, setsetprogress] = useState(0);
  let fileprocess=0;
             const  registerme1 = async() => {

           
      

          if(nameactive==="2"  && emailsactive==="2"  && subjectactive==="2"   ) 
          {
           setShowloading(true)
    
           axios.post("https://api.adventbiztech.com/contactmail.php",
        // axios.post("https://emailtemplate.easysoftstech.com/contactmail.php",
                      JSON.stringify({name: name,
                         email: emails,                         
                           subject: subject,
                           message: message,
                        
                        }),
                      {
                        headers: {
                             'Accept': 'application/json',                         
                          
                      },
                       onUploadProgress : (progressEvent) => {
      let progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      // Update state here
     
          setsetprogress(progress);
  
       fileprocess=progress;
    },
                      
      })
      .then(response => {
                          
         

                 try{

                    if(response.data.message==='success')
                    {

                        setName("")
                        setNameactive("0")
                        setemails("")
                        setemailsactive("0")
                        setsubject("")
                        setsubjectactive("0")
                        setmessage("")

                             Toast.fire({
                        icon: 'success',
                        title: 'Message sent successfully'
                      })

                     
                      
                    
                    }
                    else if(response.data.message==='error'){

                           
                           Toast.fire({
                        icon: 'error',
                        title: 'Something went wrong. Please try again later!'
                      })
                                 
                    }

                         
                        }
                        catch(f)
                        {
          
                  
                     }
                         
                         
                           
                      
                          setShowloading(false)

                    


      })
      .catch(function (error) {
          setShowloading(false)
          

               alert(error)
                 
       
      });

    }
    else{

        if(nameactive==="0")
          {
            setNameactive("1");
          }

           if(emailsactive==="0")
          {
            setemailsactive("1");
          }

           if(subjectactive==="0")
          {
            setsubjectactive("1");
          }

       

          

        
            

    }


        }
  

  return (
   
    
        <>

        {showloading ?
         <>
        <div style={{
    background: '#000000b3',
    position: 'absolute',
    top: '0',
    left: '0',
    textAlign:'center',
    width: '100%',
    height: '220vh',
    zIndex: '99999'
 

}}>

<div style={{height:'100px',marginTop: '20%',textAlign:'center'}}>
<img src='../assets/img/128x128.gif' style={{height:'100px'}}  alt='s' />
<br/> <br/>

<span style={{color:'#dedede',width:'100px',height:'100px',marginTop: '20%',fontSize:'medium'}} > Sending.. Please wait</span> <br/>

</div>

</div>
       </>
         :null}

        <Header/>

         <div >


         

        <section className="contact_us">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                                          
                <div className="contact_inner">
                  <div className="row">


                           <div className="col-sm-5">
                      <div className="contact_form_inner">
                        <div className="contact_field">
                            <h3 className='text-black mb-4 display-2' style={{fontFamily:'revert'}}>Our Presence</h3>
                            <p>Feel Free to contact us any time. We will get back to you as soon as we can!.</p>
                           <input type="text" id="fullname" value={name} className="form-control form-group" placeholder="Name" 
                        onChange={(e) => {changename(e)}} 

                          />
                           {nameactive!=="1" ?
                             null:<span style={{color:'red'}}>Enter Full Name</span>}
                          <input type="text" id="emailid" value={emails} className="form-control form-group" placeholder="Email" onChange={(e) => {changeemails(e)}} />
                       {emailsactive!=="1" ?
                             null:<span style={{color:'red'}}>Enter Valid Email</span>}
                           <input type="text" value={subject} className="form-control form-group" placeholder="Subject"  onChange={(e) => {changesubject(e)}}  />
                            {subjectactive!=="1" ?
                             null:<span style={{color:'red'}}>Enter Subject</span>}
                          <textarea className="form-control form-group" placeholder="Message" value={message}  onChange={(e) => {changemessage(e)}}  />
                          <button className="contact_form_submit"  onClick={() => {registerme1()}}>Send</button>
                        </div>
                      </div>
                    </div>


                           <div className="col-sm-5">
                      <div className="contact_form_inner">
                        <div className="contact_field">
                        <br/>
                                  <h4>  Our Head office </h4>

                            <p>S.No 62/66,CRS Farms,Kirti Gardens, Sus, Pune, Maharashtra 411021</p>

                          

                             <h4> Pune Branch Office </h4>

                            <p>14/111 Agarkar Nagar,

Sanmato Coop Housing Society, 

Behind Alankar Theatre,

Near Pune Railway Station,

Pin Code 411001
</p>

 <h4> Navi Mumbai Branch Office </h4>

                            <p>Navi Mumbai Mahape
</p>
                           

                            <p style={{display:'flex'}}>
                          
                             <i class="fa fa-phone-square" style={{transform: 'rotate(90deg)',fontSize:'25px'}} ></i>
                             <span style={{marginLeft:'10px'}}> +91 93256 55335 </span>
                           </p>

                             {/* <p style={{display:'flex'}}>
                          
                            <i class="fa fa-phone-square" style={{transform: 'rotate(90deg)',fontSize:'25px'}} ></i>
                             <span style={{marginLeft:'10px'}}> 022 40827000 </span>
                           </p> */}

                            <p style={{display:'flex'}}>
                          
                            <i class="fa fa-envelope" style={{fontSize:'25px'}} ></i>
                             <span style={{marginLeft:'10px'}}> info@adventbiztech.com </span>
                           </p>

                          

                        </div>
                      </div>
                    </div>


                    <div className="col-sm-2">

                         <div class="right_conatct_social_icon d-flex align-items-end">
                                   {/* <div class="socil_item_inner text-center">
                                    <br/>
                                      <li><a href="#" ><i style={{fontSize:'25px'}} class="fab fa-facebook-square"></i></a></li>
                                      <br/>
                                      <li><a href="#"><i style={{fontSize:'25px'}} class="fab fa-instagram"></i></a></li>
                                       <br/>

                                      <li><a href="#"><i style={{fontSize:'25px'}} class="fab fa-twitter"></i></a></li>
                                   </div> */}
                                   </div>
                    </div>







                  </div>
                  {/* <div className="contact_info_sec">
                    <h4 style={{color:'#a6a6a6'}}>Contact Info</h4>
                    <div className="d-flex info_single align-items-center">
                      <i className="fas fa-headset" />
                      <span>+91 8009 054294</span>
                    </div>
                    <div className="d-flex info_single align-items-center">
                      <i className="fas fa-envelope-open-text" />
                      <span>info@flightmantra.com</span>
                    </div>
                    <div className="d-flex info_single align-items-center">
                      <i className="fas fa-map-marked-alt" />
                      <span>1000+ Travel partners and 65+ Service city across India, USA, Canada &amp; UAE</span>
                    </div>
                  </div> */}
                </div>
              </div>


            </div>
          </div>
        </section>

        {/* <section className="map_sec">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="map_inner">
                  <h4>Find Us on Google Map</h4>

               
  <GoogleMap
    id="marker-example"
    mapContainerStyle={containerStyle}
    zoom={15}
    center={center}
  >
    
     <InfoWindow
      onLoad={onLoad}
      position={position}
    >
      <div style={divStyle}>
        <p style={{fontSize:16,fontWeight:600,margin:'0px',color:'#3D72A4',fontFamily: 'Montserrat, sans-serif'}}>DhammBhoomi Gugwad</p>
        <p style={{fontSize:14,margin:'0px',fontFamily: 'Montserrat, sans-serif',color:'#000'}}>
             At Post: Gugwad, Taluka- Jath, District- Sangli, Maharashtra State, Pin - 416404
        </p>
    <br/>
         <a href='https://www.google.com/maps?q=16.88805853614623, 75.16152014812315&z=17&hl=en' style={{fontSize:13,margin:'0px',fontWeight:'bold', fontFamily: 'Montserrat, sans-serif',color:'#007b7e'}}>
           Click here for Direction <i class="fab fa fa-map-marker"></i>
        </a>
      </div>
    </InfoWindow>


     <InfoWindow
      onLoad={onLoad}
      position={position}
    >
      <div style={divStyle}>
        <p style={{fontSize:16,fontWeight:600,margin:'0px',color:'#3D72A4',fontFamily: 'Montserrat, sans-serif'}}>DhammBhoomi Gugwad</p>
        <p style={{fontSize:14,margin:'0px',fontFamily: 'Montserrat, sans-serif',color:'#000'}}>
             At Post: Gugwad, Taluka- Jath, District- Sangli, Maharashtra State, Pin - 416404
        </p>
    <br/>
         <a href='https://www.google.com/maps?q=18.54659484783972, 73.76813413309453&z=17&hl=en' style={{fontSize:13,margin:'0px',fontWeight:'bold', fontFamily: 'Montserrat, sans-serif',color:'#007b7e'}}>
           Click here for Direction <i class="fab fa fa-map-marker"></i>
        </a>
      </div>
    </InfoWindow>
  </GoogleMap>

                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>







        <Footer/>







        </>



  )
}
