import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

export default function Ites() {

         React.useEffect(() => 
  {
      document.title="Information Technology Enabled Services (ITES) Processes and Services | AdventBiz Tech"

   
     
  }, [])


  return (

        <>
        <Header/>
  <header className="header-2 ">
        <div className="page-header min-vh-100 relative" 
        style={{backgroundImage: 'url("../assets/img/services/ites.jpg")'}}>
          <span className="mask bg-gradient-primary opacity-5" />
          <div className="container">
            <div className="row ">
              <div className="col-lg-10 text-left mt-5" style={{backgroundColor: 'rgb(20 20 20 / 60%)',borderRadius:"10px"}}>
               
                <h1 style={{fontFamily:'revert',
               
                
                }} className="text-white pt-3  display-1">Information Technology Enabled Services (ITES) </h1>
                <p className="lead text-white mt-3 " style={{fontSize:'1.1em'}}>

                ITES Processes and Services supports the needs of our customers by offering a wide range of services and solutions. We believe in helping our customers maximize their efforts by providing efficient, effective, and cost-saving technology solutions. We provide best-in-class support and development solutions through our partners, who have developed a solid track record of delivering superior results. Our partners include service providers with expertise in all technologies we offer, as well as industry leaders who have delivered products with remarkable success over time
 </p>
              </div>
            </div>
          </div>
        </div>
      </header>


       <div className='container pt-5'>       

        {/* <div class="row mt-5">
      <div class="col-lg-12 mx-auto">
        <div class="p-3 text-center mb-2">
         
          <h2 class="mt-3">Check out what&#39;s in Digital Transformation</h2>
        
        </div>
      </div>
    </div> */}


       <div className="row mt-lg-5 mt-4">
        <div className="col-lg-6 col-md-6 mb-5">
        
          <div className="card">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl">
                {/* <i className="material-icons opacity-10">manage_accounts</i> */}
                <span  className='myicons' >T</span>
            
               
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Telemarketing</h5>
              <p className="mb-3">Telemarketing strives to make it possible for businesses to reach out to their target customers and serve them via email, call center, or direct mailings. It is a powerful marketing tool that allows businesses to reach a significant amount of potential clients with an objective of gaining leads and creating brand awareness in high-traffic areas.  Our Telemarketing service allows you to reach a wide range of your potential customers through the use of automated dialers. These callers cover all key metrics and areas, allowing you to target your audience into the data they are interested in.
</p>
              {/* <a href="javascript:;" className="font-weight-bold text-xs text-uppercase font-weight-bolder text-primary icon-move-right">
                Check more
                <i className="fas fa-arrow-right text-xs ms-1" aria-hidden="true" />
              </a> */}
            </div>
          </div>
        </div>
      



                   <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-danger shadow-danger shadow text-center border-radius-xl">
                 <span  className='myicons' >H</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Helpdesk</h5>
              <p className="mb-3">A helpdesk is a central platform that customers contact to share their concerns and receive support. Helpdesks are used in a variety of settings where you need to provide support to your customers: such as partners who work with your company on a regular basis, or someone who purchased your product in a retail store. Helpdesk is designed to improve the customer experience by improving timely over-the-phone and customer service interactions. Helpdesk allows you to serve customers more efficiently and improve customer retention by providing instant, automated support at scale.
</p>
             
            </div>
          </div>
        </div>    

  <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-success shadow-success shadow text-center border-radius-xl">
                 <span  className='myicons' >C</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Customer Support Centers</h5>
              <p className="mb-3">A customer support center is a common place for organizations to host their website and provide technical assistance and troubleshooting. The ability to support its customers through the phone, email, and social media channels is invaluable in helping companies build ties with their audience. The challenges of interacting with customers can be time consuming, often requiring multiple telephone calls and lengthy emails. Even worse, it may require a conversation that could actually benefit from being written down. With Customer Support Centers, you can automate as much of that process as possible and reduce overhead costs at the same time.
</p>
              
            </div>
          </div>
        </div>



        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-info shadow-info shadow text-center border-radius-xl">
                 <span  className='myicons' >D</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Data Ware House</h5>
              <p className="mb-3">Data warehousing and data management is the process of arranging, storing and retrieving data within a database system. This is achieved by identifying the essential requirements, developing a logical and physical model of the business so that the requirements for storing, retrieving, analyzing and reporting on reports can be identified. We are Data WareHouse, one of the most reliable and trusted data suppliers in India. We have a wide range of products. Our products include Data warehouse, physical data warehousing, web based data warehouse, data modeling, BI tool etc.


</p>
              
            </div>
          </div>
        </div>



          <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-success shadow-success shadow text-center border-radius-xl">
                 <span  className='myicons' >T</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Transcription Centers</h5>
              <p className="mb-3">At Transcription Centers, we always put our customers first. Our mission is simple: to provide you with great service and the highest quality transcription services. When you need a professional transcription service for your business, you want to go to the experts. If you’re looking for transcript services from skilled workers with advanced technology, this is the place for you. When it comes time to transcribe your dictation or call recordings, Transcription Centers is a reliable and affordable way to transcribe your voice-to-text files. We provide transcription services to businesses and individuals in a variety of formats including PDF, DOC, AND WORD. Our experienced staff has mastered the art of professional transcription since 1996.


</p>
              
            </div>
          </div>
        </div>



 <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-danger shadow-danger shadow text-center border-radius-xl">
                 <span  className='myicons' >G</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">GIS Mapping for Transport tracking</h5>
              <p className="mb-3">GIS mapping is a critical technology for transport planning and monitoring. It provides a real-time view of the movement of people, vehicles and freight across the transportation network. GIS mapping allows users to develop multiple layers of data that can be viewed as two-dimensional maps or published as three-dimensional objects such as terrain models or CAD files. GIS Mapping is a software that allows you to track the location of your shipments and see the expected delivery date. It automatically tracks your shipments using GPS data and maps them in Google Maps style. You can also set up alerts for when your shipment is late or if it’s been damaged.


</p>
              
            </div>
          </div>
        </div>





 <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-warning shadow-warning shadow text-center border-radius-xl">
                 <span  className='myicons' >E</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Electronic Distribution</h5>
              <p className="mb-3">Electronic distribution makes it possible to distribute a product, service or information to more than one person in your business. The digital version of a book or report can be available by email, saved on an FTP server, or printed and bound at a printing store. Electronic services like faxing require no paper trail, so they're less likely to be misused once they're distributed electronically. We believe that ecommerce is the future and we've built an ecommerce platform that will allow you to sell your products online. There's no better way to sell than by offering people everything they want and need, all in one place. By offering quality products at incredible prices, our customers are able to save money while obtaining their desired goods.


</p>
              
            </div>
          </div>
        </div>

  
   
  









      </div>
</div>    

        <Footer/>
        </>
    
  )
}
