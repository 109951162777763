import React from 'react'

export default function Footer() {
  return (
   
    <>

           <footer className="footer pt-5" style={{backgroundColor:'#fafafa'}}>
        <div className="container">
          <div className=" row">
            <div className="col-md-3 mb-4 ms-auto">
              <div>
                <a href="#">
                  <img src="./assets/img/Advent_logo.png" className="mb-3" style={{maxWidth:'100px'}} alt="main_logo" />
                </a>
                <h6 className="font-weight-bolder mb-4">Advent Biztech</h6>
              </div>
              <div>
                <ul className="d-flex flex-row ms-n3 nav">
                  <li className="nav-item">
                    <a className="nav-link pe-1" href="https://www.facebook.com/adventbiztechsolutions?mibextid=ZbWKwL" target="_blank">
                      <i className="fab fa-facebook text-lg opacity-8" aria-hidden="true" />
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a className="nav-link pe-1" href="#" target="_blank">
                      <i className="fab fa-twitter text-lg opacity-8" aria-hidden="true" />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link pe-1" href="#" target="_blank">
                      <i className="fab fa-dribbble text-lg opacity-8" aria-hidden="true" />
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a className="nav-link pe-1" href="https://www.linkedin.com/in/advent-biztech-578257196" target="_blank">
                      <i className="fab fa-linkedin text-lg opacity-8" aria-hidden="true" />
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a className="nav-link pe-1" href="#" target="_blank">
                      <i className="fab fa-youtube text-lg opacity-8" aria-hidden="true" />
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 col-6 mb-4">
              <div>
                <h6 className="text-sm">Company</h6>
                <ul className="flex-column ms-n3 nav">
                  <li className="nav-item">
                    <a className="nav-link" href="../about-us" target="_blank">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" target="_blank">
                      Freebies
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" target="_blank">
                      Premium Tools
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" target="_blank">
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-md-2 col-sm-6 col-6 mb-4">
              <div>
                <h6 className="text-sm">Resources</h6>
                <ul className="flex-column ms-n3 nav">
                  <li className="nav-item">
                    <a className="nav-link" href="#" target="_blank">
                      Illustrations
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" target="_blank">
                      Bits &amp; Snippets
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" target="_blank">
                      Affiliate Program
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="col-md-2 col-sm-6 col-6 mb-4">
              <div>
                <h6 className="text-sm">Help &amp; Support</h6>
                <ul className="flex-column ms-n3 nav">
                  <li className="nav-item">
                    <a className="nav-link" href="../contact-us" target="_blank">
                      Our Presence
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" target="_blank">
                      Knowledge Center
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" target="_blank">
                      Custom Development
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" target="_blank">
                      Sponsorships
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 col-6 mb-4 me-auto">
              <div>
                <h6 className="text-sm">Legal</h6>
                <ul className="flex-column ms-n3 nav">
                  <li className="nav-item">
                    <a className="nav-link" href="#" target="_blank">
                      Terms &amp; Conditions
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" target="_blank">
                      Privacy Policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#" target="_blank">
                      Licenses (EULA)
                    </a>
                  </li>
                </ul>
              </div>
            </div>
         
          </div>

  

        </div>

                   <div className='container' style={{backgroundColor:'#ac1c29',maxWidth:'100%'}} >
            
                <div className=" row">
            <div className="col-sm-6" style={{color:'#fff',fontSize:'13px',paddingTop:'10px',paddingBottom:'10px'}}>

            Copyright © {(new Date()).getFullYear()} All Right Reserved<b style={{marginLeft:'5px'}} >AdventBiz Tech</b> , Designed & Developed by 
            
             <a  style={{color:'#fff',fontFamily:'revert',marginLeft:'5px',fontWeight:'600'}} href="https://www.easysoftstech.com" target="_blank"> 
                  Easysofts Tech</a>
           
           
               
              </div>
            

                        <div className="col-sm-6" style={{textAlign:'right'}} >
             
                  {/* <a  style={{color:'#fff',fontFamily:'revert'}} href="https://www.easysoftstech.com" target="_blank"> Designed By Easysofts Tech</a>.
                 */}
            </div>
          </div>
        </div>

      </footer>



    </>
  )
}
