
import {
  BrowserRouter as Router,
  Routes,
  Route,

  
} from "react-router-dom";
import Aboutus from "./Pages/Aboutus";
import Blog from "./Pages/Blog";
import Blogsingle from "./Pages/Blogsingle";
import Careers from "./Pages/Careers";
import Careersingle from "./Pages/Careersingle";
import Contact from "./Pages/Contact";
import Services from "./Pages/Services";
import Blockchain from "./Service/Blockchain";

import Cloudsolutions from "./Service/Cloudsolutions";
import Cybersecuritysolutions from "./Service/Cybersecuritysolutions";
import Datascienceanalysisservices from "./Service/Datascienceanalysisservices ";
import Digitaltrasformation from "./Service/Digitaltrasformation";
import Embeddedsystemsdesign from "./Service/Embeddedsystemsdesign";
import Engineering from "./Service/Engineering";
import Home from "./Service/Home";
import Industrialautomation from "./Service/Industrialautomation";
import Ites from "./Service/Ites";
import Itinfrastructure from "./Service/Itinfrastructure";
import Professionalservices from "./Service/Professionalservices";
import QAandtesting from "./Service/QAandtesting";
import Roboticsprocessautomation from "./Service/Roboticsprocessautomation";
import Sapsolutions from "./Service/Sapsolutions";
import Virtualreality from "./Service/Virtualreality";
function App() {
  return (
       <Router>
    <div className="App">
    
      
     <Routes>

         <Route exact path="/" element={<Home/>}/>
            <Route exact path="/digital-trasformation" element={<Digitaltrasformation/>}/>
             <Route exact path="/it-infrastructure-solutions" element={<Itinfrastructure/>}/>
             <Route exact path="/cloud-solutions" element={<Cloudsolutions/>}/>
             <Route exact path="/cyber-security-solutions" element={<Cybersecuritysolutions/>}/>
             <Route exact path="/QA-and-testing" element={<QAandtesting/>}/>
              <Route exact path="/data-science-and-analysis-services" element={<Datascienceanalysisservices/>}/>
            <Route exact path="/robotics-process-automation" element={<Roboticsprocessautomation/>}/>
             <Route exact path="/embedded-systems-design" element={<Embeddedsystemsdesign/>}/>
              <Route exact path="/block-chain" element={<Blockchain/>}/>
               <Route exact path="/virtual-reality" element={<Virtualreality/>}/>
               <Route exact path="/sap-solutions" element={<Sapsolutions/>}/>
               <Route exact path="/engineering" element={<Engineering/>}/>
                 <Route exact path="/industrial-automation" element={<Industrialautomation/>}/>
                  <Route exact path="/ites" element={<Ites/>}/>
                   <Route exact path="/professional-services" element={<Professionalservices/>}/>
                      <Route exact path="/contact-us" element={<Contact/>}/>
                       <Route exact path="/about-us" element={<Aboutus/>}/>
                          <Route exact path="/careers" element={<Careers/>}/>
                            <Route exact path="/services" element={<Services/>}/>
                             <Route exact path="/blog" element={<Blog/>}/>
                               <Route exact path="/singleblog" element={<Blogsingle/>}/>
                                <Route exact path="/careersingle" element={<Careersingle/>}/>

         </Routes>

         </div>

         </Router>
  );
}

export default App;
