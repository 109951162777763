import React from 'react'

export default function Header() {


  window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    document.getElementById("mynav").style.backgroundColor = "#fff";
    document.getElementById("mynav").style.opacity = "0.9";
  } else {
    document.getElementById("mynav").style.backgroundColor = "#fff";
     document.getElementById("mynav").style.opacity = "1";
  }
}


  return (
    
    <>

     <div className="container position-sticky z-index-sticky top-0"><div className="row"><div className="col-12">
            <nav id="mynav" style={{backgroundColor:'#fff',opacity:'1'}} className="navbar navbar-expand-lg  border-radius-xl top-0 z-index-fixed shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
              <div className="container-fluid px-0">
                <a className="navbar-brand font-weight-bolder" href="../" rel="tooltip"  data-placement="bottom" >
                  
                  <img src='../assets/img/Advent_logo.png' 
                  style={{maxWidth:'160px',borderRadius:'15px',margin:'-10px'}}  alt="advent tech logo"/>
                </a>
                <button className="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon mt-2">
                    <span className="navbar-toggler-bar bar1" />
                    <span className="navbar-toggler-bar bar2" />
                    <span className="navbar-toggler-bar bar3" />
                  </span>
                </button>
                <div className="collapse navbar-collapse pt-3 pb-2 py-lg-0 w-100" id="navigation">
                  <ul className="navbar-nav navbar-nav-hover ms-auto">



                     <li className="nav-item ms-lg-auto">
                      <a className="nav-link nav-link-icon me-2" href="/" >
                        {/* <i className="fa fa-home me-2 opacity-6" /> */}
                        <p className="d-inline text-sm z-index-1 font-weight-bold" data-bs-toggle="tooltip" data-bs-placement="bottom" >Home</p>
                      </a>
                    </li>

                   <li className="nav-item ms-lg-auto">
                      <a className="nav-link nav-link-icon me-2" href="../about-us" >
                        {/* <i className="fa fa-users me-2 opacity-6" /> */}
                        <p className="d-inline text-sm z-index-1 font-weight-bold" data-bs-toggle="tooltip" data-bs-placement="bottom" >About us</p>
                      </a>
                    </li>


                  <li className="nav-item ms-lg-auto">
                      <a className="nav-link nav-link-icon me-2" href="../blog" >
                        {/* <i className="fa fa-briefcase me-2 opacity-6" /> */}
                        <p className="d-inline text-sm z-index-1 font-weight-bold" data-bs-toggle="tooltip" data-bs-placement="bottom" >Blog</p>
                      </a>
                    </li>
          

                   <li className="nav-item ms-lg-auto">
                      <a className="nav-link nav-link-icon me-2" href="../careers" >
                        {/* <i className="fa fa-briefcase me-2 opacity-6" /> */}
                        <p className="d-inline text-sm z-index-1 font-weight-bold" data-bs-toggle="tooltip" data-bs-placement="bottom" >Careers</p>
                      </a>
                    </li>


  <li className="nav-item ms-lg-auto">
                      <a className="nav-link nav-link-icon me-2" href="../services" >
                        {/* <i className="fa fa-briefcase me-2 opacity-6" /> */}
                        <p className="d-inline text-sm z-index-1 font-weight-bold" data-bs-toggle="tooltip" data-bs-placement="bottom" >Services</p>
                      </a>
                    </li>


                    {/* <li className="nav-item dropdown dropdown-hover mx-2">
                      <a style={{fontWeight:'600'}} className="nav-link ps-2 d-flex cursor-pointer align-items-center" id="dropdownMenuBlocks" data-bs-toggle="dropdown" aria-expanded="false">
                        
                        Services
                        <img src="./assets/img/down-arrow-dark.svg" alt="down-arrow" className="arrow ms-auto ms-md-2" />
                      </a>
                      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-animation dropdown-md dropdown-md-responsive p-3 border-radius-lg mt-0 mt-lg-3" aria-labelledby="dropdownMenuBlocks">
                        <div className="d-none d-lg-block">
                          <li className="nav-item dropdown dropdown-hover dropdown-subitem">
                            <a className="dropdown-item py-2 ps-3 border-radius-md" href="../digital-trasformation">
                              <div className="w-100 d-flex align-items-center justify-content-between">
                                <div>
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Digital Transformation</h6>
                               
                                </div>
                               
                              </div>
                            </a>
                        
                          </li>


                                <li className="nav-item dropdown dropdown-hover dropdown-subitem">
                            <a className="dropdown-item py-2 ps-3 border-radius-md" href="../it-infrastructure-solutions">
                              <div className="w-100 d-flex align-items-center justify-content-between">
                                <div>
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">IT Infrastructure Solutions</h6>
                               
                                </div>
                               
                              </div>
                            </a>
                        
                          </li>

                                <li className="nav-item dropdown dropdown-hover dropdown-subitem">
                            <a className="dropdown-item py-2 ps-3 border-radius-md" href="../cloud-solutions">
                              <div className="w-100 d-flex align-items-center justify-content-between">
                                <div>
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Cloud Solutions</h6>
                               
                                </div>
                               
                              </div>
                            </a>
                        
                          </li>


                                                          <li className="nav-item dropdown dropdown-hover dropdown-subitem">
                            <a className="dropdown-item py-2 ps-3 border-radius-md" href="../cyber-security-solutions">
                              <div className="w-100 d-flex align-items-center justify-content-between">
                                <div>
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Cyber Security Solutions</h6>
                               
                                </div>
                               
                              </div>
                            </a>
                        
                          </li>


                          
                                                          <li className="nav-item dropdown dropdown-hover dropdown-subitem">
                            <a className="dropdown-item py-2 ps-3 border-radius-md" href="../QA-and-testing">
                              <div className="w-100 d-flex align-items-center justify-content-between">
                                <div>
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">QA & Testing</h6>
                               
                                </div>
                               
                              </div>
                            </a>
                        
                          </li>


                          <li className="nav-item dropdown dropdown-hover dropdown-subitem">
                            <a className="dropdown-item py-2 ps-3 border-radius-md" href="../data-science-and-analysis-services">
                              <div className="w-100 d-flex align-items-center justify-content-between">
                                <div>
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Data Science & Analysis Services</h6>
                               
                                </div>
                               
                              </div>
                            </a>
                        
                          </li>


                                                    <li className="nav-item dropdown dropdown-hover dropdown-subitem">
                            <a className="dropdown-item py-2 ps-3 border-radius-md" href="../robotics-process-automation">
                              <div className="w-100 d-flex align-items-center justify-content-between">
                                <div>
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Robotics Process Automation</h6>
                               
                                </div>
                               
                              </div>
                            </a>
                        
                          </li>



                            <li className="nav-item dropdown dropdown-hover dropdown-subitem">
                            <a className="dropdown-item py-2 ps-3 border-radius-md" href="../block-chain">
                              <div className="w-100 d-flex align-items-center justify-content-between">
                                <div>
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Block Chain</h6>
                               
                                </div>
                               
                              </div>
                            </a>
                        
                          </li>




                           <li className="nav-item dropdown dropdown-hover dropdown-subitem">
                            <a className="dropdown-item py-2 ps-3 border-radius-md" href="../virtual-reality">
                              <div className="w-100 d-flex align-items-center justify-content-between">
                                <div>
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Augmented & Virtual Reality Development Centers</h6>
                               
                                </div>
                               
                              </div>
                            </a>
                        
                          </li>



                           <li className="nav-item dropdown dropdown-hover dropdown-subitem">
                            <a className="dropdown-item py-2 ps-3 border-radius-md" href="../embedded-systems-design">
                              <div className="w-100 d-flex align-items-center justify-content-between">
                                <div>
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Embedded Systems Design</h6>
                               </div>                               
                              </div>
                            </a>                        
                          </li>



                               <li className="nav-item dropdown dropdown-hover dropdown-subitem">
                            <a className="dropdown-item py-2 ps-3 border-radius-md" href="../sap-solutions">
                              <div className="w-100 d-flex align-items-center justify-content-between">
                                <div>
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Sap Solutions & Erp Services</h6>
                               </div>                               
                              </div>
                            </a>                        
                          </li>


                           <li className="nav-item dropdown dropdown-hover dropdown-subitem">
                            <a className="dropdown-item py-2 ps-3 border-radius-md" href="../engineering">
                              <div className="w-100 d-flex align-items-center justify-content-between">
                                <div>
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Engineering and R&D</h6>
                               </div>                               
                              </div>
                            </a>                        
                          </li>


                           <li className="nav-item dropdown dropdown-hover dropdown-subitem">
                                                       <a className="dropdown-item py-2 ps-3 border-radius-md" href="../industrial-automation">
                              <div className="w-100 d-flex align-items-center justify-content-between">
                                <div>
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Industrial automation</h6>
                               </div>                               
                              </div>
                            </a>                        
                          </li>


                          <li className="nav-item dropdown dropdown-hover dropdown-subitem">
                                                       <a className="dropdown-item py-2 ps-3 border-radius-md" href="../ites">
                              <div className="w-100 d-flex align-items-center justify-content-between">
                                <div>
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">ITES</h6>
                               </div>                               
                              </div>
                            </a>                        
                          </li>


                          <li className="nav-item dropdown dropdown-hover dropdown-subitem">
                                                       <a className="dropdown-item py-2 ps-3 border-radius-md" href="../professional-services">
                              <div className="w-100 d-flex align-items-center justify-content-between">
                                <div>
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Professional Services</h6>
                               </div>                               
                              </div>
                            </a>                        
                          </li>




                        </div>
                        <div className="row d-lg-none">
                          <div className="col-md-12">
                            <div className="d-flex mb-2">
                             
                              <div className="w-100 d-flex align-items-center justify-content-between">
                               <a href='../digital-trasformation' >
                                <div>
                               
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Digital Transformation</h6>
                              
                                </div>
                                 </a>
                              </div>                              
                            </div>

                            <div className="d-flex mb-2">                            
                              <div className="w-100 d-flex align-items-center justify-content-between">
                               <a href='../it-infrastructure-solutions' >
                                <div>
                                
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">IT Infrastructure Solutions</h6>
                              
                                </div>
                                  </a>
                              </div>                              
                            </div>


                             <div className="d-flex mb-2">                            
                              <div className="w-100 d-flex align-items-center justify-content-between">
                               <a href='../cloud-solutions' >
                                <div>
                                
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Cloud Solutions</h6>
                              
                                </div>
                                  </a>
                              </div>                              
                            </div>


                             <div className="d-flex mb-2">                            
                              <div className="w-100 d-flex align-items-center justify-content-between">
                               <a href='../cyber-security-solutions' >
                                <div>
                                
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Cyber Security Solutions</h6>
                              
                                </div>
                                  </a>
                              </div>                              
                            </div>


                             <div className="d-flex mb-2">                            
                              <div className="w-100 d-flex align-items-center justify-content-between">
                               <a href='../QA-and-testing' >
                                <div>
                                
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">QA & Testing</h6>
                              
                                </div>
                                  </a>
                              </div>                              
                            </div>


                             <div className="d-flex mb-2">                            
                              <div className="w-100 d-flex align-items-center justify-content-between">
                               <a href='../data-science-and-analysis-services' >
                                <div>
                                
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Data Science & Analysis Services</h6>
                              
                                </div>
                                  </a>
                              </div>                              
                            </div>


                             <div className="d-flex mb-2">                            
                              <div className="w-100 d-flex align-items-center justify-content-between">
                               <a href='../robotics-process-automation' >
                                <div>
                                
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Robotics Process Automation</h6>
                              
                                </div>
                                  </a>
                              </div>                              
                            </div>


                             <div className="d-flex mb-2">                            
                              <div className="w-100 d-flex align-items-center justify-content-between">
                               <a href='../block-chain' >
                                <div>
                                
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Block Chain</h6>
                              
                                </div>
                                  </a>
                              </div>                              
                            </div>


                             <div className="d-flex mb-2">                            
                              <div className="w-100 d-flex align-items-center justify-content-between">
                               <a href='../virtual-reality' >
                                <div>
                                
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Augmented & Virtual Reality Development Centers</h6>
                              
                                </div>
                                  </a>
                              </div>                              
                            </div>


                             <div className="d-flex mb-2">                            
                              <div className="w-100 d-flex align-items-center justify-content-between">
                               <a href='../embedded-systems-design' >
                                <div>
                                
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Embedded Systems Design</h6>
                              
                                </div>
                                  </a>
                              </div>                              
                            </div>



                             <div className="d-flex mb-2">                            
                              <div className="w-100 d-flex align-items-center justify-content-between">
                               <a href='../sap-solutions' >
                                <div>
                                
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Sap Solutions & Erp Services</h6>
                              
                                </div>
                                  </a>
                              </div>                              
                            </div>


                             <div className="d-flex mb-2">                            
                              <div className="w-100 d-flex align-items-center justify-content-between">
                               <a href='../engineering' >
                                <div>
                                
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Engineering and R&D</h6>
                              
                                </div>
                                  </a>
                              </div>                              
                            </div>


                             <div className="d-flex mb-2">                            
                              <div className="w-100 d-flex align-items-center justify-content-between">
                               <a href='../industrial-automation' >
                                <div>
                                
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Industrial automation</h6>
                              
                                </div>
                                  </a>
                              </div>                              
                            </div>


                             <div className="d-flex mb-2">                            
                              <div className="w-100 d-flex align-items-center justify-content-between">
                               <a href='../ites' >
                                <div>
                                
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">ITES</h6>
                              
                                </div>
                                  </a>
                              </div>                              
                            </div>


                             <div className="d-flex mb-2">                            
                              <div className="w-100 d-flex align-items-center justify-content-between">
                               <a href='../professional-services' >
                                <div>
                                
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-cente align-items-center p-0">Professional Services</h6>
                              
                                </div>
                                  </a>
                              </div>                              
                            </div>


                             



                           
                          </div>
                        </div>
                      </ul>
                    </li> */}
                    
                    <li className="nav-item ms-lg-auto">
                      <a className="nav-link nav-link-icon me-2" href="../contact-us" >
                        {/* <i className="fa fa-address-card me-2 opacity-6" /> */}
                        <p className="d-inline text-sm z-index-1 font-weight-bold" data-bs-toggle="tooltip" data-bs-placement="bottom" >Our Presence</p>
                      </a>
                    </li>
                  
                  
                  </ul>
                </div>
              </div>
            </nav>
            {/* End Navbar */}
          </div></div></div>



    </>
  )
}
