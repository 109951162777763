import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

export default function Digitaltrasformation() {

         React.useEffect(() => 
  {
      document.title="Digital Trasformation | AdventBiz Tech"

   
     
  }, [])


  return (

        <>
        <Header/>
  <header className="header-2 ">
        <div className="page-header min-vh-100 relative" style={{backgroundImage: 'url("../assets/img/services/digital_transformation.jpg")'}}>
          <span className="mask bg-gradient-primary opacity-5" />
          <div className="container">
            <div className="row ">
              <div className="col-lg-10 text-left mt-5" style={{backgroundColor: 'rgb(20 20 20 / 60%)',borderRadius:"10px"}}>
               
                <h1 style={{fontFamily:'revert',
               
                
                }} className="text-white pt-3  display-1">Digital Transformation</h1>
                <p className="lead text-white mt-3 " style={{fontSize:'1.1em'}}>Digital transformation is the use of digital technologies in a strategic way in order to create value and achieve business objectives. This includes everything from creating new digital capabilities and processes, building advanced platforms, improving customer engagement and experience, and making customers more “what we do” rather than “how we do it”. <br/> We help you develop, build and grow your brand. </p>
              </div>
            </div>
          </div>
        </div>
      </header>

                {/* <div className='card card-body shadow-xl mx-3 mx-md-4 mt-n6'> */}
       <div className='container pt-5'>       

        {/* <div class="row mt-5">
      <div class="col-lg-12 mx-auto">
        <div class="p-3 text-center mb-2">
         
          <h2 class="mt-3">Check out what&#39;s in Digital Transformation</h2>
        
        </div>
      </div>
    </div> */}


       <div className="row mt-lg-5 mt-4">
        <div className="col-lg-6 col-md-6 mb-5">
        
          <div className="card">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl">
                {/* <i className="material-icons opacity-10">manage_accounts</i> */}
                <span  className='myicons' >A</span>
            
               
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Agile Transformation</h5>
              <p className="mb-3">We are an agile, fast-moving company that is always looking for ways to improve. We focus on small, incremental improvements which can really add up over time. Our third party vendor systems were not as intuitive or easy to use as we would like, and we wanted to solve this problem.
</p>
              {/* <a href="javascript:;" className="font-weight-bold text-xs text-uppercase font-weight-bolder text-primary icon-move-right">
                Check more
                <i className="fas fa-arrow-right text-xs ms-1" aria-hidden="true" />
              </a> */}
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-success shadow-success shadow text-center border-radius-xl">
                 <span  className='myicons' >C</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Cloud Transformation</h5>
              <p className="mb-3">Cloud Transformation provides technology services, consulting and managed services to enterprise and SMB clients. We help businesses by helping their IT infrastructure become more agile, efficient, and secure. Deploying cloud as a service (IaaS) for all components of your datacenter has never been easier.
</p>
              
            </div>
          </div>
        </div>



                   <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-danger shadow-danger shadow text-center border-radius-xl">
                 <span  className='myicons' >D</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2"> Digital technology integration</h5>
              <p className="mb-3">The mission of Digital technology integration is to provide best-of-the-best solutions for our clients' business needs, in order to improve their business performance and enhance their customer satisfaction. Our team consists of experienced professionals with years of successful experience in the field of computer technology, who are ready to help you solve your problem using our proprietary products and services.

</p>
             
            </div>
          </div>
        </div>    





        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-xl">
                 <span  className='myicons' >A</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Automation</h5>
              <p className="mb-3">Automation is that offers software systems for modern businesses to automate processes, promote efficiency and help them grow. We believe that whether it’s financial, sales or marketing processes that you want to automate, automation has the potential to make your business a lot more efficient. As an automated solution provider, Automation also helps companies automate their operations so that they can focus on their core business with minimal costs or risks.</p>
              
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-info shadow-info shadow text-center border-radius-xl">
               <span  className='myicons' >D</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Data Analytics</h5>
              <p className="mb-3">Our data analytics platform allows you to create custom visualizations of your data and share them with your team or the world. We make it easy for anyone in your organization to analyze their business data, understand it better, and make smarter decisions about the future.
</p>
             
            </div>
          </div>
        </div>


        
         <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-warning shadow-warning shadow text-center border-radius-xl">
                   <span  className='myicons' >D</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4 px-sm-5 ">
              <h5 className="mt-2">Digital Consultation</h5>
              <p className="mb-3">At Digital Consultation, our mission is to assist small business owners so they can succeed. We help small businesses reach their potential by providing them world-class customer service, the latest marketing tools, and business growth strategies that they can deploy immediately.
</p>
             
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-danger shadow-danger shadow text-center border-radius-xl">
                 <span  className='myicons' >W</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Workforce enablement</h5>
              <p className="mb-3">Workforce enablement company is to help people find jobs. The company will help people with the problem of sourcing, filtering and tracking resources needed to complete a job. It will focus on finding the right person for any given position and utilizing the best practices in hiring technology, to increase agility and reduce costs.
</p>
             
            </div>
          </div>
        </div>



  









      </div>
</div>    
 {/*</div>*/}
        <Footer/>
        </>
    
  )
}
