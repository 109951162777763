import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

export default function Professionalservices() {

         React.useEffect(() => 
  {
      document.title="Professional Services | AdventBiz Tech"

   
     
  }, [])


  return (

        <>
        <Header/>
  <header className="header-2 ">
        <div className="page-header min-vh-100 relative" style={{backgroundImage: 'url("../assets/img/services/professional_services.jpg")'}}>
          <span className="mask bg-gradient-primary opacity-5" />
          <div className="container">
            <div className="row ">
              <div className="col-lg-10 text-left mt-5" style={{backgroundColor: 'rgb(20 20 20 / 60%)',borderRadius:"10px"}}>
               
                <h1 style={{fontFamily:'revert',
               
                
                }} className="text-white pt-3  display-1">Professional Services</h1>
                <p className="lead text-white mt-3 " style={{fontSize:'1.1em'}}>

               Professional Services provides a broad range of services in several specialized areas that can help you to get the most out of your life and work. Our professional services provide amazing solutions for your business, whether it be for a small web hosting account or an enterprise level project. We provide reliable service, strong security and 24/7 customer care. </p>
              </div>
            </div>
          </div>
        </div>
      </header>


       <div className='container pt-5'>       

        {/* <div class="row mt-5">
      <div class="col-lg-12 mx-auto">
        <div class="p-3 text-center mb-2">
         
          <h2 class="mt-3">Check out what&#39;s in Digital Transformation</h2>
        
        </div>
      </div>
    </div> */}


       <div className="row mt-lg-5 mt-4">
        <div className="col-lg-6 col-md-6 mb-5">
        
          <div className="card">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl">
                {/* <i className="material-icons opacity-10">manage_accounts</i> */}
                <span  className='myicons' >C</span>
            
               
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Contingent Resource Engagement</h5>
              <p className="mb-3">Contingent Resource engagement. - Business Management Software- Features such as group messaging, time billing, resource management and seat management give you the tools to take your operation to the next level. As a contingent workforce solutions provider, Engagement Partners is able to offer staffing in the following industries: healthcare, IT and Engineering, Engineering and Technology
</p>
              {/* <a href="javascript:;" className="font-weight-bold text-xs text-uppercase font-weight-bolder text-primary icon-move-right">
                Check more
                <i className="fas fa-arrow-right text-xs ms-1" aria-hidden="true" />
              </a> */}
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-success shadow-success shadow text-center border-radius-xl">
                 <span  className='myicons' >P</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Project Base Resources Engagement</h5>
              <p className="mb-3">Project Base Resources provides a platform for all construction professionals and stakeholders to connect with industry insiders to upgrade the Construction Industry. We help organizations and communities to create safe, healthy and enriching environments through strategic planning that is experiential, inclusive and relevant.
</p>
              
            </div>
          </div>
        </div>



                   <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-danger shadow-danger shadow text-center border-radius-xl">
                 <span  className='myicons' >O</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Onsite/Offshore Model</h5>
              <p className="mb-3">The Onsite/Offshore model is a flexible business option for growing companies that want to outsource portions of their operations. Onsite/Offshore model – an approach where some development tasks are carried out on the client's site, while the rest of it is performed by the offshore team.

</p>
             
            </div>
          </div>
        </div>    





        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-warning shadow-warning shadow text-center border-radius-xl">
                 <span  className='myicons' >S</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Staff Augmentation</h5>
              <p className="mb-3">Staff Augmentation provides temporary employment services to corporations and individuals for short term, temporary and project-based needs including but not limited to special events, marketing, product launches, business expansion, legal compliance and technology platforms. Staff Augmentation. We're in the business of empowerment. Founded in 2008, we are a leading provider for staffing, professional staffing services, and workforce solutions serving small to mid-sized businesses throughout the US.</p>
              
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-info shadow-info shadow text-center border-radius-xl">
               <span  className='myicons' >O</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Offshore Development Centers.</h5>
              <p className="mb-3">We have a proven record of delivering complex and mission-critical applications across diverse industries. We use an experienced team of offshore consultants who speak the right language and are onsite to support design, development and deployment. At Cramer systems we are capable of handling projects of any scale and complexity in any part of the globe. We work with our clients and not for them. 
</p>
             
            </div>
          </div>
        </div>


        
        



  









      </div>
</div>    

        <Footer/>
        </>
    
  )
}
