import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

import "slick-carousel/slick/slick.css";  
import "slick-carousel/slick/slick-theme.css";  
import Slider from "react-slick";  



export default function Home() {


          var images = [    
            { img: 'assets/img/Client_logo/1.jpg' },    
            { img: 'assets/img/Client_logo/2.jpg' },    
            { img: 'assets/img/Client_logo/3.jpg' },    
            { img: 'assets/img/Client_logo/4.jpg' },    
            { img: 'assets/img/Client_logo/5.jpg' },    
            { img: 'assets/img/Client_logo/6.jpg' },
            { img: 'assets/img/Client_logo/7.jpg' },
            { img: 'assets/img/Client_logo/8.jpg' },
            { img: 'assets/img/Client_logo/9.jpg' },
            { img: 'assets/img/Client_logo/10.jpg' },
            { img: 'assets/img/Client_logo/11.jpg' },    
            { img: 'assets/img/Client_logo/12.jpg' },
            { img: 'assets/img/Client_logo/13.jpg' },
            { img: 'assets/img/Client_logo/14.jpg' },
            { img: 'assets/img/Client_logo/15.jpg' },
            { img: 'assets/img/Client_logo/16.jpg' },
            { img: 'assets/img/Client_logo/17.jpg' },
            { img: 'assets/img/Client_logo/18.jpg' },
            { img: 'assets/img/Client_logo/19.jpg' },
            { img: 'assets/img/Client_logo/20.jpg' },
            { img: 'assets/img/Client_logo/21.jpg' },
            { img: 'assets/img/Client_logo/22.jpg' },
            { img: 'assets/img/Client_logo/23.jpg' },
            { img: 'assets/img/Client_logo/24.jpg' },
            { img: 'assets/img/Client_logo/25.jpg' },
            { img: 'assets/img/Client_logo/26.jpg' },
            { img: 'assets/img/Client_logo/27.jpg' },
            { img: 'assets/img/Client_logo/28.jpg' },
            { img: 'assets/img/Client_logo/29.jpg' },
          
            
          ];   


    var imgSlides = () =>  
    images.map(num => (  
      <div className="imgpad">  
          <img className="imgdetails" src= {num.img} width="100%"/>    
      </div>  
    ));  

var settings = {  
          dots: true,  
          infinite: true,  
          speed: 500,  
          centerMode: true,  
          slidesToShow: 4,  
          slidesToScroll: 1  
          };  
     React.useEffect(() => 
  {
      document.title="Home | AdventBiz Tech"

   
     
  }, [])
  return (

        <>
        <Header/>
          <header>
        <nav className="navbar navbar-expand-lg navbar-dark navbar-absolute bg-transparent shadow-none">
          <div className="container">
            <a className="navbar-brand text-white" href="javascript:;">Material Design</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-header-2" aria-controls="navbar-header-2" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbar-header-2">
              <ul className="navbar-nav mx-auto">
                <li className="nav-item">
                  <a className="nav-link text-white" href="javascript:;">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="javascript:;">
                    About Us
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="javascript:;">
                    Contact Us
                  </a>
                </li>
              </ul>
              <ul className="nav navbar-nav">
                <li className="nav-item">
                  <a className="nav-link text-white" href="https://twitter.com/CreativeTim">
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white mx-2" href="https://www.facebook.com/CreativeTim">
                    <i className="fab fa-facebook" />
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="https://www.instagram.com/CreativeTimOfficial">
                    <i className="fab fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="page-header min-vh-100" loading="lazy">
         <video width="100%"  autoPlay muted loop  >
                                    <source src={"../assets/video/adventbiztech_backvideo.mp4"} type="video/mp4" />

                                 Your browser does not support HTML video.
                                 </video>
          <span className="mask bg-gradient-dark opacity-5" />
          <div className="container">
            <div className="row">
            {/* style={{backgroundColor: 'rgb(20 20 20 / 60%)'}} */}
              <div className="col-lg-10 col-md-7 d-flex justify-content-center flex-column" >
                <h1 className="text-white mb-4 display-2" style={{fontFamily:'revert'}}>Committed to providing the simplest solutions for the most complex problems!</h1>
                {/* <p className="text-white opacity-8 lead pe-5 me-5">At the heart of every business is a great brand. What you put out there is what you'll get back.
We help you develop, build and grow your brand. </p> */}
                <div className="buttons">
                <a href='../services'>  <button type="button" className="btn btn-white mt-4">Get Started</button> </a>
                 {/* <a href='../about-us'>   <button type="button" className="btn text-white shadow-none mt-4">Read more</button></a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>



  <div className="container-fluid" style={{backgroundColor:'#fff'}}>
        <div className="row d-sm-flex align-items-center justify-content-between">
          <div className="col-md-6 order-2 order-sm-1 p-3 p-sm-5" >
            <div className="p-0 p-sm-5">
             
              <h1 className="mb-4 display-4" style={{fontWeight: 600,fontFamily:'revert'}}>About us</h1>
              <p className="text-secondary" style={{lineHeight: 2,color:'black',fontFamily:'revert'}}>
             
  <b> Advent Biztech Solutions </b>  <span style={{color:'#000'}}> is a fast growing, innovative and tech driven organization. Our main service provisioning is the IT solutions. We provide integrated consulting services to help businesses improve their internal systems, processes and procedures.
Advent Biztech Solutions Private Limited was established year 9th September 2019.We are a team of dedicated 150+ professionals in all services, with highly experienced professionals, experience and expertise. We are committed towards excellence and value every ounce of our resource.
          </span>    </p>
              <a href="../about-us"  className="btn px-4 py-3 mx-n4 text-white d-flex align-items-center justify-content-between">
              
                <button class="btns default">Read More</button>
              </a>
            </div>
          </div>
          <div className="col-md-6 order-1 order-sm-2" style={{backgroundImage: 'url(../assets/img/work_culture.jpg)', height: '90vh',  backgroundPosition: 'center', backgroundSize: 'cover'}} />
        </div>
      </div>


  
{/* 
         <section className="py-6" style={{background:'#fff'}} id="specialist">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mx-auto text-center pb-4">
              <h2 style={{fontFamily:'revert'}} >Meet our specialists</h2>
              <p className='pt-3' style={{fontSize:'1.1rem',color:'black'}} >In this era of technology, almost every company needs technical staffing partner to automate their company process.

As an outcome of this, it is becoming very difficult to find a right company who provides customized solutions to meet your company specifications within restricted cost variety.

If you are considering seeking the solutions for your staffing needs and various IT web-based solutions, then we the right choice.
</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="p-3 text-center">
                <div className="icon icon-shape icon-md bg-gradient-primary shadow mx-auto">
                  <i className="material-icons opacity-10">person</i>
                </div>
                <h5 className="mt-4">Check our team</h5>
                <p>People in this world shun people for being great. For being a bright color. For standing out.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="p-3 text-center">
                <div className="icon icon-shape icon-md bg-gradient-info shadow mx-auto">
                  <i className="material-icons opacity-10">support_agent</i>
                </div>
                <h5 className="mt-4">Support 24/7</h5>
                <p>We get insulted by others, lose trust for those others. We get back here to follow my dreams</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mx-md-auto">
              <div className="p-3 text-center">
                <div className="icon icon-shape icon-md bg-gradient-warning shadow mx-auto">
                  <i className="material-icons opacity-10">update</i>
                </div>
                <h5 className="mt-4">Unlimited revisions</h5>
                <p>The time is now to be okay to be the greatest you. Would you believe in what you believe in?</p>
              </div>
            </div>
          </div>
        </div>
        <div class="h-divider">
  <div class="shadows"></div>
  </div>
      </section> */}

  

    {/* <section className="section-services" id='mservices' style={{backgroundImage:'url(../assets/img/services-back.jpg)',    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%'}} >
        <div className="container mt-n5">
          <div className="row justify-content-center text-center">
            <div className="col-md-10 col-lg-8">
              <div className="header-section">
                <h2 className="title">Our <span>Services</span></h2>
               
              </div>
            </div>
          </div>
          <div className="row">
        
            <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="part-1">
              
                  <h3 className="title">Digital Transformation</h3>
                </div>
                <div className="part-2">
                  <p className="description">

                  Digital transformation is the use of digital technologies in a strategic way in order to create value..
                  
                  </p>
                  <a href="../digital-trasformation"><i className="fas fa-arrow-circle-right" />Read More</a>
                </div>
              </div>
            </div>

             <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="part-1 partblue">
              
                  <h3 className="title">IT Infrastructure Solutions</h3>
                </div>
                <div className="part-2 partblue1">
                  <p className="description">
                  We offer a wide spectrum of IT Infrastructure Services in the form of infrastructure design, implementation...
                  </p>
                  <a href="../it-infrastructure-solutions"><i className="fas fa-arrow-circle-right " />Read More</a>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="part-1">
                
                  <h3 className="title">Cloud Solutions</h3>
                </div>
                <div className="part-2">
                  <p className="description">

                 Cloud Solutions and DevOps provides computing services to individuals, businesses, and organizations that need...
                  
                  </p>
                  <a href="../cloud-solutions"><i className="fas fa-arrow-circle-right" />Read More</a>
                </div>
              </div>
            </div>

             <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="part-1 partblue">
                
                  <h3 className="title">Cyber Security Solutions</h3>
                </div>
                <div className="part-2 partblue1">
                  <p className="description">
                 Cyber Security Solutions is your company's one-stop shop for security solutions.We can help you reduce risk...
                  </p>
                  <a href="../cyber-security-solutions"><i className="fas fa-arrow-circle-right " />Read More</a>
                </div>
              </div>
            </div>


            <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="part-1">
                 
                  <h3 className="title">Professional Services</h3>
                </div>
                <div className="part-2">
                  <p className="description">

                  Professional Services provides a broad range of services in several specialized areas that can help you to get...
                  
                  </p>
                  <a href="../professional-services"><i className="fas fa-arrow-circle-right" />Read More</a>
                </div>
              </div>
            </div>

             <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="part-1 partblue">
                 
                  <h3 className="title">QA & Testing</h3>
                </div>
                <div className="part-2 partblue1">
                  <p className="description">
                  Testing has always been an integral part of any software development process. It's a crucial stage where defects...
                  </p>
                  <a href="../QA-and-testing"><i className="fas fa-arrow-circle-right " />Read More</a>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="part-1">
                
                  <h3 className="title">Data Science & Analysis Services</h3>
                </div>
                <div className="part-2">
                  <p className="description">

                 Data Science is the interdisciplinary field concerned with the catching and understanding of data, including...
                  
                  </p>
                  <a href="../data-science-and-analysis-services"><i className="fas fa-arrow-circle-right" />Read More</a>
                </div>
              </div>
            </div>

             <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="part-1 partblue">
               
                  <h3 className="title">Robotics Process Automation</h3>
                </div>
                <div className="part-2 partblue1">
                  <p className="description">
                  The company at Advent Biztech Solutions Pvt. Ltd. is committed to providing a better user experience through...
                  </p>
                  <a href="../robotics-process-automation"><i className="fas fa-arrow-circle-right " />Read More</a>
                </div>
              </div>
            </div>


            <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="part-1">
                
                  <h3 className="title">Block Chain</h3>
                </div>
                <div className="part-2">
                  <p className="description">

                  Block Chain is a technology that allows someone to create a record of something that happened in the past...
                  
                  </p>
                  <a href="../block-chain"><i className="fas fa-arrow-circle-right" />Read More</a>
                </div>
              </div>
            </div>

             <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="part-1 partblue">
                 
                  <h3 className="title">Augmented & VR Development Centers</h3>
                </div>
                <div className="part-2 partblue1">
                  <p className="description">
                 Augmented &amp; Virtual Reality Development Centers are a perfect first step to learning augmented...
                  </p>
                  <a href="../virtual-reality"><i className="fas fa-arrow-circle-right " />Read More</a>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="part-1">
                 
                  <h3 className="title">Embedded Systems Design</h3>
                </div>
                <div className="part-2">
                  <p className="description">

                  Embedded Systems Design is a specialization that focuses on the development of complex embedded systems...
                  
                  </p>
                  <a href="../embedded-systems-design"><i className="fas fa-arrow-circle-right" />Read More</a>
                </div>
              </div>
            </div>

             <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="part-1 partblue">
                  
                  <h3 className="title">Sap Solutions & Erp Services</h3>
                </div>
                <div className="part-2 partblue1">
                  <p className="description">
                  SAP solutions offer you the opportunity to easily and cost effectively streamline your business processes. We provide...
                  </p>
                  <a href="../sap-solutions"><i className="fas fa-arrow-circle-right " />Read More</a>
                </div>
              </div>
            </div>


            <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="part-1">
                  
                  <h3 className="title">Engineering and R&D</h3>
                </div>
                <div className="part-2">
                  <p className="description">

                  Engineering and research and development are core capabilities that give brands the edge in the hypercompetitive...
                  
                  </p>
                  <a href="../engineering"><i className="fas fa-arrow-circle-right" />Read More</a>
                </div>
              </div>
            </div>

             <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="part-1 partblue">
                  
                  <h3 className="title">Industrial automation</h3>
                </div>
                <div className="part-2 partblue1">
                  <p className="description">
                 Our Industrial Automation solutions help you automate your business, while enabling productivity and efficiency...
                  </p>
                  <a href="../industrial-automation"><i className="fas fa-arrow-circle-right " />Read More</a>
                </div>
              </div>
            </div>



            <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="part-1">
                
                  <h3 className="title">ITES</h3>
                </div>
                <div className="part-2">
                  <p className="description">

                  ITES Processes and Services supports the needs of our customers by offering a wide range of services and solutions...
                  
                  </p>
                  <a href="../ites"><i className="fas fa-arrow-circle-right" />Read More</a>
                </div>
              </div>
            </div>

            
           
           
          </div>
        </div>
        
      </section> */}

  <section className="py-7 pb-7" style={{background:'#fff'}} id="specialist">
        <div className="container">
          <div className="row">
           <div className="col-lg-12 mx-auto text-center pb-4">
  <h2 style={{fontFamily:'revert'}} >Our Happy Clients</h2>
  <p>Feel good. Share confidence. Feed your passion. It's why we built Happy Clients</p>
  </div>
          
        
          <Slider  
    dots={false}  
        slidesToShow={6}  
        slidesToScroll={2}  
        autoplay={true}  
        arrows={true}  
        autoplaySpeed={2000}>{imgSlides()}</Slider> 

       

        </div>

        </div>

        </section>


        <Footer/>
        </>
    
  )
}
