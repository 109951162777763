import React,{useState} from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import { useNavigate , useLocation } from 'react-router-dom'

export default function Blogsingle() {
  const location = useLocation();


   const [showloading,setShowloading] = useState(false);

   const [loginlist,setLoginlist] = useState([]);


     const [heading,setheading] = useState("");
      const [content,setcontent] = useState("");
       const [dates,setdates] = useState("");
        const [imgurl,setimgurl] = useState("");

           const [imgurl2,setimgurl2] = useState("");
            const [imgurl3,setimgurl3] = useState("");
              const [imgurl4,setimgurl4] = useState("");



              const  getmenudata = async(menuid) => { 

        setShowloading(true)    

       fetch("https://api.adventbiztech.com/bloglistsingleweb.php",  {
                  method: 'POST',   
               
                  headers: {
                    'Accept': 'application/json',     
                    
                  },
                    body: JSON.stringify({id: menuid})
                
                }).then((response) => response.json())
                  .then((response) => {

                    
                    console.log(response)
                  

                    setheading(response[0].head)
                      setcontent(response[0].descs)
                       setdates(response[0].dates)
                         setimgurl(response[0].img)
                           setimgurl2(response[0].img2)
                             setimgurl3(response[0].img3)
                               setimgurl4(response[0].img4)
                    
                     
                        setShowloading(false)
       
                  }).catch(error => {
                         setShowloading(false)
                         
                             alert(error)
                           
                })

    }


           React.useEffect(() => 
  {
      document.title="Blog | AdventBiz Tech"
document.body.style.setProperty("background-color", "white", "important");
    
      //document.body.style.backgroundImage = "url('assets/img/services.jpg')";
    getmenudata(location.state.lginid)
     
  }, [])


  return (
    
    <>

      {showloading ?
         <>
        <div style={{
    background: '#000000b3',
    position: 'absolute',
    top: '0',
    left: '0',
    textAlign:'center',
    width: '100%',
    height: '220vh',
    zIndex: '99999'
 

}}>

<div style={{height:'100px',marginTop: '20%',textAlign:'center'}}>
<img src='../assets/img/128x128.gif' style={{height:'100px'}}  alt='s' />
<br/> <br/>

<span style={{color:'#dedede',width:'100px',height:'100px',marginTop: '20%',fontSize:'medium'}} > Please wait.....</span> <br/>

</div>

</div>
       </>
         :null}

    <Header />
    <div className="container">
            <div className="row ">
              <div className="col-lg-12 text-center mt-8" >
               
       {/* <h1 style={{fontFamily:'revert'}} className="text-white pt-3 mt-8  display-1">Blog</h1> */}
</div>
</div>
</div>


   <section className="py-3"  style={{background:'#fff'}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-10">
              <h3 className="mb-2" style={{fontFamily:'revert'}}>{heading}</h3> 
               <p  style={{fontFamily:'revert'}}>Date : {dates}</p>
            </div>
          </div>
          <div className="row">

            <img src={"http://api.adventbiztech.com/"+imgurl} style={{width:'80%'}} alt="img-blur-shadow" className="img-fluid border-radius-lg" loading="lazy" />


            {imgurl2!=="" || imgurl2===null ?
              <>
                  <img src={"http://api.adventbiztech.com/"+imgurl2} style={{width:'80%'}} alt="img-blur-shadow" className="img-fluid border-radius-lg" loading="lazy" />

              </>
            :null}

               {imgurl3!=="" || imgurl3===null ?
              <>
                  <img src={"http://api.adventbiztech.com/"+imgurl3} style={{width:'80%'}} alt="img-blur-shadow" className="img-fluid border-radius-lg" loading="lazy" />

              </>
            :null}

              {imgurl4!=="" || imgurl4===null ?
              <>
                  <img src={"http://api.adventbiztech.com/"+imgurl4} style={{width:'80%'}} alt="img-blur-shadow" className="img-fluid border-radius-lg" loading="lazy" />

              </>
            :null}



            <p className='p-4' style={{fontFamily:'revert'}}><span style={{fontFamily:'revert'}} dangerouslySetInnerHTML={ { __html: content } }></span></p>
            {

       loginlist.map((menulist)=>{                            
                              return (
                                <>
                               
            <div className="col-lg-3 col-sm-6">
              <div className="card card-plain" style={{backgroundColor:'white'}}>
                <div className="card-header p-0 position-relative">
                  <a className="d-block blur-shadow-image">
                  
                  </a>
                </div>
                <div className="card-body px-3">
                  <h5>
                    <a href="javascript:;" className="text-dark font-weight-bold" style={{fontFamily:'revert'}}>{heading}</a>
                  </h5>
                    
                  <a href="javascript:;" className="text-info text-sm icon-move-right">Read More
                    <i className="fas fa-arrow-right text-xs ms-1" aria-hidden="true" />
                  </a>
                </div>
              </div>
            </div>

              </>

        )})}
           
          </div>
        </div>
      </section>


      <Footer/>

    </>


    
  )
}
