import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

export default function Blockchain() {

         React.useEffect(() => 
  {
      document.title="Block Chain | AdventBiz Tech"

   
     
  }, [])


  return (

        <>
        <Header/>
  <header className="header-2 ">
        <div className="page-header min-vh-100 relative" 
        style={{backgroundImage: 'url("../assets/img/services/block_chain.jpg")'}}>
          <span className="mask bg-gradient-primary opacity-5" />
          <div className="container">
            <div className="row ">
              <div className="col-lg-10 text-left mt-5" style={{backgroundColor: 'rgb(20 20 20 / 60%)',borderRadius:"10px"}}>
               
                <h1 style={{fontFamily:'revert',
               
                
                }} className="text-white pt-3  display-1">Block Chain</h1>
                <p className="lead text-white mt-3 " style={{fontSize:'1.1em'}}>

               Block Chain is a technology that allows someone to create a record of something that happened in the past. The information in the block chain can not be changed, and no one on the block chain will be able to disagree with the written information. A block chain is a transaction database shared by all nodes participating in a system based on the Bitcoin protocol. A full copy of a currency's block chain contains every transaction ever executed in the currency.
 </p>
              </div>
            </div>
          </div>
        </div>
      </header>


       <div className='container pt-5'>       

        {/* <div class="row mt-5">
      <div class="col-lg-12 mx-auto">
        <div class="p-3 text-center mb-2">
         
          <h2 class="mt-3">Check out what&#39;s in Digital Transformation</h2>
        
        </div>
      </div>
    </div> */}


       <div className="row mt-lg-5 mt-4">
        <div className="col-lg-6 col-md-6 mb-5">
        
          <div className="card">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl">
                {/* <i className="material-icons opacity-10">manage_accounts</i> */}
                <span  className='myicons' >B</span>
            
               
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Blockchain Application Development</h5>
              <p className="mb-3">Blockchain Application Development  is a technology that develops and provides services related to blockchain applications. Blockchain applications are software programs that use distributed ledger technology (also known as a “smart contract”) to fulfill their functions on a network. We are a team of smart and technical people who love working together. We specialize in developing high-quality Blockchain applications. We have experience on development, design and deployment of leading edge solutions in the field of smart contracts using Solidity/C++, HTML5/JavaScript and NodeJS
</p>
              {/* <a href="javascript:;" className="font-weight-bold text-xs text-uppercase font-weight-bolder text-primary icon-move-right">
                Check more
                <i className="fas fa-arrow-right text-xs ms-1" aria-hidden="true" />
              </a> */}
            </div>
          </div>
        </div>
      



                   <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-danger shadow-danger shadow text-center border-radius-xl">
                 <span  className='myicons' >A</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Advisory And Consulting Services</h5>
              <p className="mb-3">In the world of business, sales and marketing goals must be met in order for a company to grow. Finding new clients and converting them into paying customers can be hard work, but with our expert service offerings, we are here to assist you every step of the way. We are Advisory and Consulting Services firm who have helped numerous small and medium size organizations in their business and operations. We understand the need of each client so as we can deliver best solutions.</p>
             
            </div>
          </div>
        </div>    

  <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-success shadow-success shadow text-center border-radius-xl">
                 <span  className='myicons' >E</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Enterprise Solutions</h5>
              <p className="mb-3">We are a software and technology services company helping clients across various industries to use the most comprehensive, scalable, powerful and flexible solutions. Our team of young, talented, and passionate experts are here to help you achieve your goals, improve your business processes, and increase sales. We’re not satisfied until we have helped our customers eliminate the complexity from their businesses by improving the way they think about technology and data, moving from product to service.</p>
              
            </div>
          </div>
        </div>



        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-warning shadow-warning shadow text-center border-radius-xl">
                 <span  className='myicons' >B</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Blockchain Framework</h5>
              <p className="mb-3">We started BLOCKCHAIN in order to offer a pragmatic solution to the growing number of people struggling with identity theft. At such a critical time, we believe blockchain technology must be adopted and utilized at all levels of society, from individuals to infrastructure. We believe in a paradigm shift in business and social relationship. We are launching a new ecosystem, decentralized and self-powered with the Blockchain framework. Our mission is to change the way business is done today by creating more transparency, efficiency, and most of all trust in transactions. Our products are designed for any budget and intended for both B2B and B2C users.  
</p>
              
            </div>
          </div>
        </div>




   <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-danger shadow-danger shadow text-center border-radius-xl">
                 <span  className='myicons' >I</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Integration Services</h5>
              <p className="mb-3">We at Advent Biztech Solutions Pvt. Ltd. is an award winning team of experts who help you define your brand, build a customer base and create awesome products. We do this by working closely with you to understand your mission, values and business model. We work with you and your team to build a roadmap for growth that includes defined targets along with the resources needed to achieve them.
We specialize in data management, strategic planning, branding and ad ops. If you’re looking for professional designers with highly innovative ideas to solve your business challenges, reach out to us directly!
 
</p>
              
            </div>
          </div>
        </div>






  <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-xl">
                 <span  className='myicons' >T</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">TESTING SERVICES</h5>
              <p className="mb-3">Testing services are a great way to help your customers before they purchase something. By testing their products, you can find out if the product will fit and function as well as perform.  There are many different types of testing that can be done on various types of products.  We offer testing services from our testing facility to all our clients. This facility is used by many companies in our area for various activities that help with product development.

</p>
              
            </div>
          </div>
        </div>

        
   
  









      </div>
</div>    

        <Footer/>
        </>
    
  )
}
