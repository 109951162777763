import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

export default function Itinfrastructure() {

         React.useEffect(() => 
  {
      document.title="IT Infrastructure Solutions | AdventBiz Tech"

   
     
  }, [])


  return (

        <>
        <Header/>
  <header className="header-2 ">
        <div className="page-header min-vh-100 relative" 
        style={{backgroundImage: 'url("../assets/img/services/it_infrastructure_solutions.jpg")'}}>
          <span className="mask bg-gradient-primary opacity-5" />
          <div className="container">
            <div className="row ">
              <div className="col-lg-10 text-left mt-5" style={{backgroundColor: 'rgb(20 20 20 / 60%)',borderRadius:"10px"}}>
               
                <h1 style={{fontFamily:'revert',
               
                
                }} className="text-white pt-3  display-1">IT Infrastructure Solutions</h1>
                <p className="lead text-white mt-3 " style={{fontSize:'1.1em'}}>

                We offer a wide spectrum of IT Infrastructure Services in the form of infrastructure design, implementation and integration as well as technical consultancy. Our team of professional engineers, consultants and management professionals is ready to deliver of your project. At IT Infrastructure Services, we are committed to providing the best in-house managed IT services to our clients. From enterprise infrastructure management, server and desktop support, professional consulting and project management, we are here to help you succeed and get back to work.
 </p>
              </div>
            </div>
          </div>
        </div>
      </header>


       <div className='container pt-5'>       

        {/* <div class="row mt-5">
      <div class="col-lg-12 mx-auto">
        <div class="p-3 text-center mb-2">
         
          <h2 class="mt-3">Check out what&#39;s in Digital Transformation</h2>
        
        </div>
      </div>
    </div> */}


       <div className="row mt-lg-5 mt-4">
        <div className="col-lg-6 col-md-6 mb-5">
        
          <div className="card">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl">
                {/* <i className="material-icons opacity-10">manage_accounts</i> */}
                <span  className='myicons' >R</span>
            
               
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Remote Infrastructure Management</h5>
              <p className="mb-3">In a remote work environment and as a digital nomad, teams work from a laptop. We recognize that remote workers have unique needs, and have worked hard to build a product that addresses those needs. Over time, it became difficult to manage infrastructure without letting down clients or getting tasks done. We created Remote Infrastructure Management as a solution for managing all the computers in your organization from any internet-enabled device. 
</p>
              {/* <a href="javascript:;" className="font-weight-bold text-xs text-uppercase font-weight-bolder text-primary icon-move-right">
                Check more
                <i className="fas fa-arrow-right text-xs ms-1" aria-hidden="true" />
              </a> */}
            </div>
          </div>
        </div>
      



                   <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-danger shadow-danger shadow text-center border-radius-xl">
                 <span  className='myicons' >F</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2"> Facility Management Services</h5>
              <p className="mb-3">At Facility Management Services, we are committed to continually improving our services and developing highly personalized, cutting edge solutions tailored for each client. Our clients rely on us for professional assistance with facility management services, including multi-site management, HR solutions, leased space management and contract administration to name a few. We customize each solution specifically to meet company needs along the journey</p>
             
            </div>
          </div>
        </div>    

  <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-success shadow-success shadow text-center border-radius-xl">
                 <span  className='myicons' >O</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">On Premise Infrastructure Management</h5>
              <p className="mb-3">Our mission is to provide the most efficient in-house hosting and infrastructure management services by enabling companies to better manage their infrastructure through technology. When looking for a company to take care of their infrastructure, we've found businesses are looking for many things: value, timeliness, quality and support. In response, we've created a unique hybrid solution which includes our technology, 24 x 7 priority support and expert knowledge that no other company can match.</p>
              
            </div>
          </div>
        </div>



        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-xl">
                 <span  className='myicons' >I</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Infrastructure Project Management</h5>
              <p className="mb-3">Infrastructure project Management provides experienced service providers, who have the ability to complete complex assignments in a timely manner. We are always looking for new ways to help our customers solve their problems. If you are looking for a company that specializes in infrastructure projects but also offers other services than just that, you’re at the right place.</p>
              
            </div>
          </div>
        </div>



        
        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-info shadow-info shadow text-center border-radius-xl">
               <span  className='myicons' >N</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">Network Design, Implementation & Management</h5>
              <p className="mb-3">Network Design, Implementation & Management is a one-stop shop for all your network needs. Whether you are looking for a new place to work, or need some help with your existing infrastructure, our team of seasoned professionals have the experience and expertise to help you get it done right. We offer a wide range of services including: installation, configuration and maintenance of wired and wireless networks; site surveys; LAN design; mobile device management; managed IT services including Wi-Fi deployment and troubleshooting. Our experienced team will take the time to understand your needs and help you design the network that works best for you.
</p>
             
            </div>
          </div>
        </div>


        
         <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-warning shadow-warning shadow text-center border-radius-xl">
                   <span  className='myicons' >D</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4 px-sm-5 ">
              <h5 className="mt-2">Datacenter Design, Implementation & Management</h5>
              <p className="mb-3">Our goal at Advent Biztech Solutions Pvt. Ltd. is to be the partner of choice when it comes to building, managing, and optimizing your datacenter. We work with our clients to build the right architecture and infrastructure for their business. We help you to determine the right approach to decouple application workloads from infrastructure, optimize the performance of your workloads, achieve best-in-class operational results and increase your chances of winning new customers
</p>
             
            </div>
          </div>
        </div>
   



  









      </div>
</div>    

        <Footer/>
        </>
    
  )
}
