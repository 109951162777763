import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

export default function Datascienceanalysisservices () {

         React.useEffect(() => 
  {
      document.title="Data Science & Analysis Services | AdventBiz Tech"

   
     
  }, [])


  return (

        <>
        <Header/>
  <header className="header-2 ">
        <div className="page-header min-vh-100 relative" 
        style={{backgroundImage: 'url("../assets/img/services/data_science_and_analysis_services.jpg")'}}>
          <span className="mask bg-gradient-primary opacity-5" />
          <div className="container">
            <div className="row ">
              <div className="col-lg-10 text-left mt-5" style={{backgroundColor: 'rgb(20 20 20 / 60%)',borderRadius:"10px"}}>
               
                <h1 style={{fontFamily:'revert',
               
                
                }} className="text-white pt-3  display-1">Data Science & Analysis Services</h1>
                <p className="lead text-white mt-3 " style={{fontSize:'1.1em'}}>

                Data Science is the interdisciplinary field concerned with the catching and understanding of data, including, but not limited to, exploring and explaining patterns and trends, modeling complex systems and making decisions based on those models. Data Science combines skills from computer science, and mathematics
 </p>
              </div>
            </div>
          </div>
        </div>
      </header>


       <div className='container pt-5'>       

        {/* <div class="row mt-5">
      <div class="col-lg-12 mx-auto">
        <div class="p-3 text-center mb-2">
         
          <h2 class="mt-3">Check out what&#39;s in Digital Transformation</h2>
        
        </div>
      </div>
    </div> */}


       <div className="row mt-lg-5 mt-4">
        <div className="col-lg-6 col-md-6 mb-5">
        
          <div className="card">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-primary shadow-primary shadow text-center border-radius-xl">
                {/* <i className="material-icons opacity-10">manage_accounts</i> */}
                <span  className='myicons' >S</span>
            
               
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">STRATEGY AND BUILD UP</h5>
              <p className="mb-3">Consulting, Building Algorithms, Defining Data Processes 
 <br/>Strategy and build up are two critical components of any business. Your strategy will be the outline, or blueprint of your ultimate success, while your build up is the step-by-step guide that you need to make certain that your business meets all its benchmarks in order to achieve its goals.
</p>
              {/* <a href="javascript:;" className="font-weight-bold text-xs text-uppercase font-weight-bolder text-primary icon-move-right">
                Check more
                <i className="fas fa-arrow-right text-xs ms-1" aria-hidden="true" />
              </a> */}
            </div>
          </div>
        </div>
      



                   <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-danger shadow-danger shadow text-center border-radius-xl">
                 <span  className='myicons' >I</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">INTEGRATION MANAGEMENT  </h5>
              <p className="mb-3"> INFRASTRUCTURE Configuration, Monitoring and Maintenance 

<br/>Integrating a business process to achieve greater efficiency with technology is an important management goal. Integrating products and services increases the chances of success, but identifying and employing the right strategy is necessary. Leverage your team, processes and knowledge to gain insights and intelligence that drive faster development by integrating multiple systems.

                </p>
            </div>
          </div>
        </div>    


        
          <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-info shadow-info shadow text-center border-radius-xl">
               <span  className='myicons' >D</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">DATA ANALYSIS</h5>
              <p className="mb-3">Data Presentation, Provide Actionable Insights <br/>
Data Analysis is an important part of the professional life of any individual. It helps him to understand the properties and characteristics of the item or event under study. Data analysis is the process of creating usable information from raw data through appropriate statistical methods. It also involves data cleaning and transforming, producing summary tables and graphs. The outcome of data analysis is used to make decisions which can be used to improve processes and revenue.

</p>
             
            </div>
          </div>
        </div>


        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-xl">
                 <span  className='myicons' >D</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">DATA PROCESSING</h5>
              <p className="mb-3">Batch Data Processing / Real Time Data Processing <br/> We understand that the right data processing can be the key to your business’ success. From simple tasks such as merging invoices, to complex tasks like extracting detailed information from a database and tying it back to existing processes, we can help you make critical changes in your operation. Our system processes a variety of data such as sales, inventory, production and more to help enforce our policies and create reports
</p>
              
            </div>
          </div>
        </div>



        
        <div className="col-lg-6 col-md-6 mb-5">
          <div className="card mb-3">
            <div className="ps-4 mt-n4">
              <div className="icon icon-lg icon-shape bg-gradient-success shadow-success shadow text-center border-radius-xl">
               <span  className='myicons' >A</span>
              </div>
            </div>
            <div className="card-body border-radius-lg position-relative overflow-hidden pb-4">
              <h5 className="mt-2">AI/MACHINE LEARNING</h5>
              <p className="mb-3">Analyze Hybrid Data, Foresee and Tackle Setbacks. <br/>
AI/MACHINE LEARNING is the future. Every day, our world becomes more connected, and the same goes for people in general. Apps are becoming smarter, integrating more data points to better predict your behavior so hopefully you won't have to wait in the uncomfortable line of people who have been waiting for the last 15 minutes.
Machine learning is the computer science discipline that makes use of algorithms to construct systems capable of learning and adapting to new situations based on past experiences. In machine learning, a computer program learns how to do something through experience, rather than being explicitly programmed to perform a task.

</p>
             
            </div>
          </div>
        </div>




        
        



  









      </div>
</div>    

        <Footer/>
        </>
    
  )
}
